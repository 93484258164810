/*
  Theme Name: Gerow - Business Consulting HTML Template
  Support: themedox@gmail.com
  Description: Gerow - Business Consulting HTML Template.
  Version: 1.0
*/

/* CSS Index
============================
01. Variable CSS
02. Header
03. Mobile Menu
04. Search
05. OffCanvas
06. Slider
07. Banner
08. Breadcrumb
09. About
10. Overview
11. Features
12. Choose
13. Services
14. Cta
15. Counter
16. Estimate
17. Project
18. Faq
19. Request
20. Team
21. Testimonial
22. Pricing
23. Contact
24. Blog
25. Brand
26. Error
27. Footer
28. Preloader
============================
*/



/*=============================
	1. Google Fonts
===============================*/
@font-face {
    font-family: 'OHCFont';
    /* You can name it whatever you want */
    src: url('../fonts/bfont.ttf') format('truetype');
    /* Adjust the path accordingly */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*=============================
	01. Variable CSS
===============================*/
:root {
    --tg-body-font-family: 'OHCFont', sans-serif;
    --tg-heading-font-family: 'Urbanist', sans-serif;
    --tg-primary-color: #0055FF;
    --tg-secondary-color: #121267;
    --tg-body-font-color: #334770;
    --tg-heading-font-color: #121267;
    --tg-paragraph-color: #334770;
    --tg-section-background: #F8FAFF;
    --tg-body-font-size: 16px;
    --tg-body-font-weight: 500;
    --tg-heading-font-weight: 700;
    --tg-body-line-height: 1.62;
    --tg-heading-line-height: 1.2;
    --tg-text-transform: normal;
    --tg-blue: #0049DC;
    --tg-metallic-blue: #324B7D;
    --tg-cornflower-blue: #B3CCFF;
    --tg-steel-blue: #B6C1DB;
    --tg-lavender: #E6EEFF;
    --tg-indigo: #6610f2;
    --tg-purple: #6f42c1;
    --tg-pink: #FF005C;
    --tg-red: #FF3C3C;
    --tg-orange: #fd7e14;
    --tg-yellow: #FFB930;
    --tg-green: #219653;
    --tg-teal: #20c997;
    --tg-cyan: #0dcaf0;
    --tg-white: #ffffff;
    --tg-black: #000;
    --tg-gray: #F1F4FC;
    --tg-gray-two: #F1F5FF;
    --tg-gray-three: #96A1B8;
    --tg-gray-four: #667594;
    --tg-border-color: #E1E5EE;
    --facebook: #3b5998;
    --twitter: #00acee;
    --linkedin: #1976d2;
    --pinterest: #3b5998;
    --youtube: #c4302b;
    --skype: #00aff0;
}

body {
    font-family: var(--tg-body-font-family);
    font-weight: var(--tg-body-font-weight);
    font-size: var(--tg-body-font-size);
    line-height: var(--tg-body-line-height);
    color: var(--tg-body-font-color);
    font-style: normal;
}

img,
.img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.fix {
    overflow: hidden
}

a,
button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
}

a:focus,
.btn:focus,
button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
    color: var(--tg-primary-color);
    text-decoration: none;
}

a,
button {
    color: var(--tg-primary-color);
    outline: medium none;
    text-decoration: none;
}

.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
    outline: 0
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    margin-top: 0px;
    margin-bottom: .7rem;
    font-style: normal;
    line-height: var(--tg-heading-line-height);
    font-weight: var(--tg-heading-font-weight);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

.list-wrap {
    margin: 0px;
    padding: 0px;
}

.list-wrap li {
    list-style: none
}

p {
    font-size: var(--tg-body-font-size);
    font-weight: var(--tg-body-font-weight);
    line-height: var(--tg-body-line-height);
    color: var(--tg-paragraph-color);
    text-transform: var(--tg-text-transform);
    margin-bottom: 15px;
}

.p-break {
    text-align: justify;
    word-break: auto-phrase;
}

.p-break-center {
    text-align-last: center;
}


hr {
    border-bottom: 1px solid var(--tg-primary-color);
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

label {
    color: var(--tg-body-font-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

*::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

::selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

*::-moz-placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}

*::placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}

.theme-overlay {
    position: relative
}

.theme-overlay::before {
    background: var(--tg-primary-color) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
}

.separator {
    border-top: 1px solid var(--tg-primary-color);
}

/* Bootstrap 5 */
.container {
    padding-left: 15px;
    padding-right: 15px;
}

.row {
    --bs-gutter-x: 30px;
}

.row.g-0 {
    --bs-gutter-x: 0;
}

.gutter-y-30 {
    --bs-gutter-y: 30px;
}

.bg-position-bottom {
    background-position: bottom !important;
}

/*=============================
	1. Button style
===============================*/
.btn {
    user-select: none;
    -moz-user-select: none;
    background: var(--tg-primary-color) none repeat scroll 0 0;
    border: medium none;
    border-radius: 30px;
    color: var(--tg-white);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1;
    margin-bottom: 0;
    padding: 18px 18px 18px 22px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
    border: 0;
    position: relative;
    z-index: 1;
}

.btn::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    margin-left: 45px;
}

.btn::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: var(--tg-blue);
    border-radius: 30px;
    z-index: -1;
    transition: all 0.3s ease 0s;
}

.btn:hover::before {
    width: 100%;
}

.btn:hover {
    color: var(--tg-white);
}

.btn.btn-two::after {
    display: none;
}

.transparent-btn {
    border: 2px solid var(--tg-primary-color);
    background: transparent;
    color: var(--tg-primary-color);
}

.btn-three {
    padding: 19px 30px;
}

.transparent-btn-two::before,
.btn-three::before {
    display: none;
}

.transparent-btn-two::after,
.btn-three::after {
    content: "";
    background-image: url(../img/icons/right-arrow.svg);
    filter: brightness(0) invert(1);
    width: 18px;
    height: 12px;
    margin-left: 12px;
}

.btn-three:hover {
    background: var(--tg-secondary-color);
}

.transparent-btn-two {
    border: 2px solid var(--tg-primary-color);
    color: var(--tg-primary-color);
    background: transparent;
    padding: 17px 30px;
}

.transparent-btn-two::after {
    filter: none;
    transition: all 0.3s ease 0s;
}

.transparent-btn-two:hover {
    background: var(--tg-primary-color);
}

.transparent-btn-two:hover::after {
    filter: brightness(0) invert(1);
}

.breadcrumb>.active {
    color: var(--tg-primary-color);
}


/* scrollUp */
.float-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: fixed;
    bottom: 10%;
    right: 30px;
    font-size: var(--tg-body-font-size);
    border-radius: 6px;
    z-index: 99;
    color: var(--tg-white);
    text-align: center;
    cursor: pointer;
    background: var(--tg-primary-color);
    transition: all 0.3s ease;
    border: none;
}

/* Adjust bottom percentage for each icon so they do not overlap */
.scroll-top {
    bottom: 165px;
    /* Adjust as needed */
}

.message-icon {
    bottom: 120px;
    /* Adjust as needed */
}

.telephone-icon {
    bottom: 75px;
    /* Adjust as needed */
}

.whatsapp-icon {
    background-color: #25D366;
    bottom: 30px;
    /* Adjust as needed */
}

/* Hover effects for icons */
.float-icon:hover {
    opacity: 0.8;
    bottom: calc(bottom + 5%);
    /* Slightly raise the icon on hover */
}


.float-icon:last-child::after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.float-icon:hover {
    background: var(--tg-secondary-color);
}

/*=============================
	02. Header
===============================*/
.custom-container {
    max-width: 1310px;
}

.custom-container-two {
    max-width: 1360px;
}

.custom-container-three {
    max-width: 1800px;
}

.transparent-header {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 9;
    height: auto;
}

#header-fixed-height.active-height {
    display: block;
    height: 96px;
}

.heder-top-wrap {
    background: var(--tg-secondary-color);
    overflow: hidden;
    padding: 9px 0;
}

.header-top-left .list-wrap {
    display: flex;
    align-items: center;
    gap: 10px 35px;
}

.header-top-left .list-wrap li {
    font-size: 14px;
    color: var(--tg-lavender);
    display: flex;
    align-items: center;
    line-height: 1.28;
    position: relative;
}

.header-top-left .list-wrap li::before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 500%;
    background: var(--tg-metallic-blue);
}

.header-top-left .list-wrap li:last-child::before {
    display: none;
}

.header-top-left .list-wrap li i {
    font-size: 20px;
    margin-right: 8px;
    line-height: 0;
    color: var(--tg-cornflower-blue);
}

.header-top-left .list-wrap li a {
    color: var(--tg-lavender);
}

.header-top-left .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.header-top-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header-contact {
    position: relative;
    margin-right: 35px;
}

.header-contact::before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 500%;
    background: var(--tg-metallic-blue);
}

.header-contact a {
    font-size: 14px;
    line-height: 1.28;
    color: var(--tg-lavender);
    display: flex;
    align-items: center;
}

.header-contact a i {
    font-size: 20px;
    margin-right: 8px;
}

.header-contact a:hover {
    color: var(--tg-primary-color);
}

.header-social .list-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-social .list-wrap li a {
    font-size: 15px;
    color: var(--tg-lavender);
}

.header-social .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.menu-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}


.navbar-wrap {
    display: flex;
    flex-grow: 1;
}

.navbar-wrap ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0;
    margin: 0 0 0 auto;
}

.navbar-wrap ul li {
    list-style: none;
    display: block;
    position: relative;
}

.navbar-wrap ul li a {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--tg-secondary-color);
    padding: 40px 15px;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
}

.navbar-wrap>ul>li.active>a,
.navbar-wrap>ul>li:hover>a {
    color: var(--tg-primary-color);
}

.navbar-wrap>ul>li.menu-item-has-children>a::after {
    content: "\f107";
    font-weight: 700;
    font-family: "Font Awesome 5 Free";
    color: var(--tg-paragraph-color);
    margin-left: 8px;
    transition: all 0.3s ease-out 0s;
}

.navbar-wrap>ul>li.menu-item-has-children:hover>a::after,
.navbar-wrap>ul>li.menu-item-has-children.active>a::after {
    color: var(--tg-primary-color);
}

.main-menu .navigation li.menu-item-has-children .dropdown-btn {
    display: none;
}

.header-action>ul {
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.header-action>ul li {
    position: relative;
    margin-left: 25px;
}

.header-action ul li:first-child {
    margin-left: 0;
}

.header-action ul li a {
    color: var(--tg-heading-font-color);
    font-size: 14px;
}

.header-action ul li.header-search {
    line-height: 0;
}

.header-action ul li.header-search a {
    color: #334770;
    font-size: 22px;
    line-height: 0;
    transition: all 0.3s ease-out 0s;
}

.header-action ul li.header-search a:hover {
    color: var(--tg-primary-color);
}

.header-action .header-btn .btn {
    color: var(--tg-white);
    font-size: 15px;
    padding: 16px 33px;
    border: 2px solid transparent;
}

.header-action .header-btn .btn::before {
    display: none;
}

.header-action .header-btn .btn:hover {
    background: transparent;
    border-color: var(--tg-primary-color);
    color: var(--tg-primary-color);
}

.navbar-wrap ul li .sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    min-width: 230px;
    border: 1px solid #f5f5f5;
    background: var(--tg-white);
    margin: 0 0;
    transform: scale(1, 0);
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    -moz-box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    box-shadow: 0px 30px 70px 0px rgba(137, 139, 142, 0.15);
    border-radius: 5px;
    padding: 18px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
}

.navbar-wrap ul li .sub-menu .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
}

.navbar-wrap ul li .sub-menu li {
    margin-left: 0;
    text-align: left;
    display: block;
}

.navbar-wrap ul li .sub-menu li a {
    padding: 9px 15px 9px 25px;
    line-height: 1.4;
    font-size: 16px;
    font-weight: 500;
    color: var(--tg-secondary-color);
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 5px;
}

.navbar-wrap ul li .sub-menu li a::before {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    border-radius: 2px;
    background: var(--tg-primary-color);
    transition: .3s ease-in-out;
}

.navbar-wrap ul li .sub-menu>li.active>a,
.navbar-wrap ul li .sub-menu li a:hover {
    color: var(--tg-primary-color);
}

.navbar-wrap ul li .sub-menu>li.active>a::before,
.navbar-wrap ul li .sub-menu li a:hover::before {
    width: 10px;
}

.navbar-wrap ul li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.sticky-menu {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    z-index: 99;
    background: var(--tg-white);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
}

/* header-two */
.header-style-two .heder-top-wrap {
    padding: 0;
}

.header-style-two .header-top-left {
    padding-left: 25px;
}

.header-style-two .header-top-right {
    gap: 20px;
}

.header-top-btn a {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 0;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    padding: 17px 25px;
}

.header-top-btn a i {
    font-size: 16px;
    margin-right: 10px;
}

.header-top-btn a:hover {
    background: var(--tg-blue);
}

.header-style-two .menu-area {
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.05);
    background: var(--tg-white);
    padding: 0 0 0 30px;
}

.header-style-two .navbar-wrap ul {
    margin: 0 0 0 60px;
}

.header-style-two .navbar-wrap ul li a {
    padding: 32px 15px;
}

.header-contact-two {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.header-contact-two .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    line-height: 0;
    border-radius: 50%;
    font-size: 20px;
    margin-right: 7px;
}

.header-contact-two .content span {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: var(--tg-paragraph-color);
    line-height: 1;
    margin-bottom: 5px;
}

.header-contact-two .content a {
    font-weight: 700;
    font-size: 16px;
    display: block;
    line-height: 1;
    color: var(--tg-secondary-color);
}

.header-contact-two .content a:hover {
    color: var(--tg-primary-color);
}

.header-style-two .header-action ul>li>a {
    padding: 29px 29px;
    display: block;
    min-height: 80px;
    display: flex;
    align-items: center;
}

.header-action .offcanvas-menu a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    min-width: 26px;
    padding: 29px 29px;
    justify-content: center;
}

.header-action .offcanvas-menu a span {
    width: 26px;
    height: 2px;
    border-radius: 2px;
    background: var(--tg-paragraph-color);
    transition: .3s linear;
}

.header-action .offcanvas-menu a span:nth-child(2) {
    width: 23px;
}

.header-action .offcanvas-menu a span:nth-child(3) {
    width: 15px;
}

.header-style-two .header-action .offcanvas-menu a:hover span {
    background: var(--tg-primary-color);
}

.header-style-two .header-action>ul li {
    margin-left: 0;
}

.header-style-two .header-action>ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    background: #D9D9D9;
}

.header-style-two .header-action>ul li:first-child::before {
    display: none;
}

.header-style-two.sticky-menu .heder-top-wrap {
    display: none;
}

.header-style-two.sticky-menu .menu-area {
    box-shadow: none;
}


/* header-three */
.header-action .offcanvas-menu.offcanvas-menu-two a {
    padding: 0 0;
}

.header-style-three .header-action>ul li {
    margin-left: 20px;
}

.header-style-three .header-action>ul li:first-child {
    margin-left: 0;
}

.header-style-three .header-action>ul {
    margin-left: 50px;
}


/* header-four */
.header-style-four .heder-top-wrap {
    padding: 0;
}

.header-style-four .header-top-right {
    gap: 20px;
}

.header-style-four .menu-area {
    background: var(--tg-white);
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.05);
}


.header-style-five .header-btn .btn {
    text-transform: capitalize;
}

.header-style-six .menu-area {
    background: var(--tg-white);
}


/*=============================
	03. Mobile Menu
===============================*/
.nav-outer .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    display: none;
    color: var(--tg-white);
    margin-right: 30px;
    top: 15px;
}



.mobile-search {
    padding: 0 20px 25px 25px;
}

.mobile-search form {
    position: relative;
}

.mobile-search input {
    display: block;
    width: 100%;
    border: none;
    padding: 10px 45px 10px 20px;
    font-size: 14px;
    font-weight: 500;
    height: 45px;
    background: var(--tg-gray);
    color: var(--tg-secondary-color);
}

.mobile-search input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--tg-heading-font-color);
}

.mobile-search button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    padding: 0;
    right: 20px;
    line-height: 1;
    background: transparent;
    color: var(--tg-secondary-color);
}

.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 0px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)e;
    -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
}

.mobile-menu .navbar-collapse {
    display: block !important;
}

.mobile-menu .nav-logo {
    position: relative;
    padding: 30px 25px;
    text-align: left;
}

.mobile-menu-visible {
    overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.mobile-menu .navigation li.current>a:before {
    height: 100%;
}

.menu-backdrop {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.5);
}

.mobile-menu-visible .menu-backdrop {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--tg-white);
    padding: 0px 0px;
    z-index: 5;
    box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}

.mobile-menu-visible .mobile-menu .menu-box {
    opacity: 1;
    visibility: visible;
}

.mobile-menu .close-btn {
    position: absolute;
    right: 15px;
    top: 28px;
    line-height: 30px;
    width: 35px;
    text-align: center;
    font-size: 20px;
    color: #292b37;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
    margin: 0;
    padding: 0;
}

.mobile-menu .navigation ul {
    padding: 0;
    margin: 0;
}

.mobile-menu .navigation li {
    position: relative;
    display: block;
    border-top: 1px solid rgb(0 0 0 / 10%);
}

.mobile-menu .navigation:last-child {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.mobile-menu .navigation li>ul>li:first-child {
    border-top: 1px solid rgb(0 0 0 / 10%);
}

.mobile-menu .navigation li>a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 60px 10px 25px;
    font-size: 16px;
    font-weight: 700;
    color: var(--tg-heading-font-color);
    text-transform: capitalize;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
}

.mobile-menu .navigation li ul li>a {
    font-size: 16px;
    margin-left: 20px;
    text-transform: capitalize;
}

.mobile-menu .navigation li ul li ul li a {
    margin-left: 40px;
}

.mobile-menu .navigation li ul li ul li ul li a {
    margin-left: 60px;
}

.mobile-menu .navigation li>a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
    position: absolute;
    right: 15px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: #292b37;
    background: var(--tg-gray);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open i {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: var(--tg-primary-color);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
    display: none;
}

.mobile-menu .social-links ul {
    display: flex;
    position: relative;
    text-align: center;
    padding: 30px 20px 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.mobile-menu .social-links li {
    position: relative;
    display: inline-block;
    margin: 0px 6px 10px;
}

.mobile-menu .social-links li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
    line-height: 32px;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: 1px solid var(--tg-gray);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.mobile-menu .social-links li a:hover {
    border-color: var(--tg-primary-color);
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 27px;
    cursor: pointer;
    line-height: 1;
    color: var(--tg-primary-color);
    display: none;
    margin-top: 6px;
    padding: 5px 10px;
    border: 2px solid var(--tg-primary-color);
}


/*=============================
	04. Search
===============================*/
.search-popup-wrap {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.90);
    height: 100%;
    width: 100%;
    z-index: 99;
    padding: 100px 0;
    display: none;
}

.search-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.search-wrap .title {
    font-size: 47px;
    margin: 0 0 70px 0;
    font-weight: 700;
}

.search-form {
    position: relative;
}

.search-form input {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--tg-primary-color);
    padding: 10px 50px 20px;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
    background: transparent;
    color: var(--tg-heading-font-color);
}

.search-form input::placeholder {
    font-size: 30px;
    color: var(--tg-heading-font-color);
    opacity: .5;
}

.search-btn {
    position: absolute;
    right: 20px;
    background: transparent;
    border: 0;
    font-size: 25px;
    color: var(--tg-primary-color);
    top: 50%;
    transform: translateY(-50%);
}

.search-close {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 30px;
    color: var(--tg-primary-color);
    cursor: pointer;
}


/*=============================
	05. OffCanvas
===============================*/
.extra-info {
    background: var(--tg-white) none repeat scroll 0 0;
    height: 100%;
    padding: 30px;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.7s ease 0s;
    width: 340px;
    z-index: 999;
    overflow-y: scroll;
    transform: translateX(100%);
}

.extra-info.active {
    transform: translateX(0);
}

.close-icon {
    margin-top: -16px;
    text-align: right;
}

.close-icon>button {
    background: transparent;
    border: 0 none;
    color: var(--tg-primary-color);
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

.extra-info .logo-side img {
    max-width: 151px;
}

.side-info {
    border-top: 1px solid var(--tg-primary-color);
    padding-top: 25px;
}

.contact-list h4 {
    color: var(--tg-heading-font-color);
    font-weight: 700;
    font-size: 18px;
}

.contact-list p {
    color: var(--tg-paragraph-color);
    margin: 0;
    margin-bottom: 2px;
    line-height: 26px;
}

.side-instagram {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.side-instagram li {
    width: 33.3333%;
    flex: 0 0 33.3333%;
    padding: 5px;
}

.side-instagram li>a {
    display: block;
}

.side-instagram img {
    width: 100%;
    border-radius: 5px;
}

.social-icon-right>a {
    color: var(--tg-primary-color);
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}

.social-icon-right>a:hover {
    color: var(--tg-secondary-color);
}

.offcanvas-overly {
    position: fixed;
    background: var(--tg-black);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.offcanvas-overly.active {
    opacity: .5;
    visibility: visible;
}

/*=============================
	06. Slider
===============================*/
.slider-bg {
    min-height: 100vh;
    display: flex !important;
    align-items: center;
    padding: 200px 0 120px;
    z-index: 1;
    cursor: grab;
    position: relative;
    overflow: hidden;
}

.slider-content .sub-title {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: block;
    color: var(--tg-primary-color);
    padding-top: 18px;
    margin-bottom: 10px;
}

.slider-content .sub-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.slider-content .title {
    position: relative;
    font-weight: 800;
    font-size: 60px;
    margin-bottom: 20px;
    line-height: 1;
}

.slider-content p {
    z-index: 1;
    position: relative;
    color: var(--tg-paragraph-color);
    margin-bottom: 45px;
    width: 85%;
}

.slider-shape {
    position: absolute;
    left: 15%;
    bottom: 23%;
    animation: alltuchtopdown 8s infinite linear;
}

/*=============================
	07. Banner
===============================*/
.banner-bg-two {
    background-size: cover;
    background-position: center;
    min-height: 770px;
    display: flex;
    align-items: flex-end;
    padding: 170px 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.banner-content-two {
    width: 85%;
}

.banner-content-two .sub-title {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--tg-primary-color);
    background: var(--tg-lavender);
    border-radius: 50px;
    display: inline-block;
    padding: 4px 13px;
    margin-bottom: 15px;
}

.banner-content-two .title {
    font-weight: 800;
    font-size: 60px;
    margin-bottom: 20px;
    line-height: 1;
}

.banner-content-two p {
    margin-bottom: 30px;
    color: var(--tg-paragraph-color);
}

.banner-content-two .banner-btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.banner-content-two .banner-btn .play-btn {
    display: flex;
    align-items: center;
    color: var(--tg-secondary-color);
}

.banner-content-two .banner-btn .play-btn span {
    text-decoration: underline;
}

.banner-content-two .banner-btn .play-btn i {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
    color: var(--tg-primary-color);
    font-size: 18px;
    margin-right: 12px;
}

.banner-content-two .banner-btn .play-btn:hover {
    color: var(--tg-primary-color);
}

.banner-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.banner-shape-wrap img:nth-child(1) {
    left: 47%;
    bottom: 47%;
    animation: float-bob-x 4s infinite linear;
}

.banner-shape-wrap img:nth-child(2) {
    right: 45%;
    bottom: 22%;
    animation: alltuchtopdown 8s infinite linear;
}

.banner-shape-wrap img:nth-child(3) {
    right: 26%;
    bottom: 0;
    z-index: -2;
}

@keyframes float-bob-x {
    0% {
        transform: translateX(-30px);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(-30px);
    }
}

/* banner-three */
.banner-area-three {
    padding: 200px 0 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.banner-area-three::after,
.banner-area-three::before {
    content: "";
    position: absolute;
    left: 30px;
    top: 60px;
    width: 550px;
    height: 550px;
    background: #CEF0FF;
    opacity: 0.3;
    filter: blur(75px);
    border-radius: 50%;
    z-index: -3;
}

.banner-area-three::after {
    background: #E4CEFF;
    top: -105px;
    left: auto;
    right: 30%;
}

.banner-content-three {
    margin-top: 60px;
}

.banner-content-three .title {
    font-weight: 800;
    font-size: 60px;
    margin-bottom: 25px;
    line-height: 1.06;
}

.banner-content-three p {
    margin-bottom: 15px;
    color: var(--tg-paragraph-color);
}

.banner-content-three .banner-form {
    position: relative;
    width: 93%;
}

.banner-content-three .banner-form input {
    width: 100%;
    border: 1px solid #D7DFF0;
    border-radius: 30px;
    background: var(--tg-white);
    font-size: 16px;
    font-weight: 400;
    color: var(--tg-secondary-color);
    padding: 16px 70px 16px 28px;
    height: 60px;
}

.banner-content-three .banner-form input::placeholder {
    font-size: 16px;
    color: var(--tg-paragraph-color);
    font-weight: 400;
}

.banner-content-three .banner-form button {
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 5px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border: none;
    border-radius: 50%;
    font-size: 24px;
    line-height: 0;
}

.banner-content-three .banner-form button:hover {
    background: var(--tg-secondary-color);
}

.ball {
    opacity: 1;
    z-index: -2;
    position: absolute;
    border-radius: 50%;
}

.banner-img-three {
    position: relative;
    text-align: right;
    padding-right: 80px;
}

.banner-img-three img.main-img {
    clip-path: polygon(0 0, 85% 0, 100% 15%, 100% 100%, 15% 100%, 0 85%);
    border-radius: 5px;
}

.banner-img-three img:not(.main-img) {
    position: absolute;
}

.banner-img-three img.img-two {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    left: 6%;
    bottom: 24%;
}

.banner-img-three img.img-three {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    right: -4%;
    top: 21%;
}

.banner-shape-wrap-three img {
    position: absolute;
    z-index: -1;
}

.banner-shape-wrap-three img:nth-child(1) {
    right: 47.5%;
    bottom: 41%;
    animation: ribbonRotate 6s infinite linear;
}

.banner-shape-wrap-three img:nth-child(2) {
    right: 18%;
    bottom: 3%;
    animation: alltuchtopdown 8s infinite linear;
}

@keyframes ribbonRotate {

    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    25%,
    75% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    50% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}

/* banner-four */
.banner-bg-four {
    background-size: cover;
    background-position: center;
    padding: 65px 0 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.banner-content-four {
    margin-top: 105px;
}

.banner-content-four .sub-title {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    display: inline-block;
    background: var(--tg-lavender);
    color: var(--tg-primary-color);
    border-radius: 30px;
    padding: 4px 13px;
    margin-bottom: 20px;
}

.banner-content-four .title {
    margin-bottom: 15px;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.1;
}

.banner-content-four p {
    margin-bottom: 35px;
    color: var(--tg-paragraph-color);
    width: 90%;
}

.banner-shape-wrap-four img {
    position: absolute;
    z-index: -1;
}

.banner-shape-wrap-four img:nth-child(1) {
    left: 15%;
    bottom: 25%;
    animation: alltuchtopdown 8s infinite linear;
}

.banner-shape-wrap-four img:nth-child(2) {
    right: 46%;
    bottom: 39%;
}

.banner-shape-wrap-four img:nth-child(3) {
    right: 15%;
    top: 0;
}

/* banner-five */
.banner-area-five {
    position: relative;
    background: #F2F7FF;
    padding: 140px 0 100px;
    z-index: 1;
    overflow: hidden;
}

.banner-area-five::before {
    content: "";
    position: absolute;
    left: 17%;
    top: -100px;
    width: 460px;
    height: 460px;
    background: #FBEDFF;
    filter: blur(200px);
    z-index: -1;
}

.banner-area-five::after {
    content: "";
    position: absolute;
    right: -10%;
    top: -15%;
    width: 1100px;
    height: 600px;
    background: linear-gradient(88.44deg, #E0FAF3 0.58%, rgba(239, 247, 255, 0) 67.54%);
    border-radius: 326.687px;
    transform: rotate(-42.01deg);
    z-index: -1;
}

.banner-content-five .title {
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 50px;
}

.banner-content-five .title span {
    position: relative;
    z-index: 1;
}

.banner-content-five .title span svg {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -4px;
    z-index: -1;
}

.has-animation.active-animation .banner-content-five .title span svg path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    -webkit-animation: dash_animation 10s linear forwards;
    animation: dash_animation 10s linear forwards;
}

@-webkit-keyframes dash_animation {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash_animation {
    to {
        stroke-dashoffset: 0;
    }
}

.banner-content-five p {
    margin-bottom: 25px;
    color: var(--tg-black);
}

.banner-img-five {
    position: relative;
    margin-left: 90px;
    z-index: 1;
}

.banner-img-five img:not(.main-img) {
    position: absolute;
}

.banner-img-five .main-img {
    max-width: unset;
}

.banner-img-five .shape-one {
    right: -19%;
    bottom: 12%;
}

.banner-img-five .shape-two {
    left: 35%;
    top: 38%;
    animation: float-bob-x 4s infinite linear;
}

.banner-img-five .shape-three {
    right: 5%;
    top: 23%;
    animation: alltuchtopdown 8s infinite linear;
}


/*=============================
	08. Breadcrumb
===============================*/
.breadcrumb-bg {
    background-size: cover;
    background-position: center;
    padding: 290px 0px 30px 0px;
    position: relative;
    z-index: 1;
}



.breadcrumb-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #03091b6e;
    opacity: .85;
    z-index: 11;
}

.breadcrumb-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.breadcrumb-shape-wrap img:nth-child(1) {
    left: 0;
    top: 0;
}

.breadcrumb-shape-wrap img:nth-child(2) {
    right: 10%;
    top: 0;
}

.breadcrumb-content {
    position: relative;
    z-index: 111;
    text-align: center;
}

.breadcrumb-content .title {
    margin-bottom: 12px;
    color: var(--tg-white);
    font-size: 44px;
    line-height: 1.1;
    text-transform: capitalize;
}

.breadcrumb-content .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
}

.breadcrumb-content .breadcrumb-item {
    color: var(--tg-primary-color);
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}

.breadcrumb-content .breadcrumb-item a {
    color: #9FA9BE;
}

.breadcrumb-content .breadcrumb-item a:hover {
    color: var(--tg-primary-color);
}

.breadcrumb-content .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 10px;
    color: #9FA9BE;
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    margin-top: 7px;
}

.breadcrumb-content .breadcrumb-item+.breadcrumb-item {
    padding-left: 10px;
}

/*=============================
	09. About
===============================*/
.about-bg {
    background-size: cover;
    background-position: center;
    padding: 140px 0 200px;
}

.about-img-wrap {
    position: relative;
    text-align: center;
    z-index: 1;
    padding-bottom: 15px;
    margin-top: 16px;
}

.about-img-wrap img:not(.main-img) {
    position: absolute;
    z-index: -1;
}

.about-img-wrap img:nth-child(2) {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.about-img-wrap img.main-img {
    border-radius: 50%;
    width: 363px;
    object-fit: cover;
    height: 363px;
    border: 5px solid var(--tg-white);
}

.about-img-wrap img:nth-child(3) {
    right: 19%;
    top: -10%;
    animation: alltuchtopdown 8s infinite linear;
}

.about-content {
    width: 88%;
}

.section-title .sub-title {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    color: var(--tg-primary-color);
    position: relative;
    padding-top: 18px;
    margin-bottom: 10px;
}

.section-title .sub-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 4px;
    background: var(--tg-primary-color);
    border-radius: 4px;
}

.section-title .title {
    font-size: 40px;
    margin-bottom: 0;
}

.about-content p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
}


/* about-two */
.about-area-two {
    position: relative;
}

.about-area-two::before {
    content: "";
    position: absolute;
    left: 13%;
    top: 0;
    width: 630px;
    height: 630px;
    background: linear-gradient(154.68deg, #8894FF 11.62%, #FF8585 90.47%);
    opacity: 0.1;
    filter: blur(75px);
    z-index: -1;
}

.about-content-two p {
    margin-bottom: 20px;
    color: var(--tg-paragraph-color);
}

.about-list .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 0;
    margin-bottom: 30px;
}

.about-list .list-wrap li {
    font-family: var(--tg-heading-font-family);
    color: var(--tg-paragraph-color);
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    width: 50%;
    line-height: 1.3;
    display: flex;
    align-items: flex-start;
}

.about-list .list-wrap li img {
    margin-right: 10px;
}

.success-wrap .list-wrap {
    display: flex;
    align-items: center;
    border-top: 1px solid #D9D9D9;
    padding-top: 30px;
    margin-bottom: 40px;
}

.success-wrap .list-wrap li {
    width: 50%;
}

.success-wrap .list-wrap li .count {
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 8px;
    letter-spacing: 2px;
    line-height: .8;
}

.success-wrap .list-wrap li p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1;
}

.about-content-two .btn {
    background: transparent;
    border-color: var(--tg-primary-color);
    color: var(--tg-primary-color);
}

.about-content-two .btn::after {
    color: var(--tg-white);
}

.about-content-two .btn:hover {
    color: var(--tg-white);
}

.about-img-two {
    position: relative;
    padding-left: 218px;
}

.about-img-two .main-img {
    position: relative;
    text-align: right;
}

.about-img-two .main-img img {
    border-radius: 15px;
    border: 9px solid var(--tg-white);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
}

.about-img-two .main-img .play-btn {
    position: absolute;
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0 85 255 / 80%);
    border-radius: 50%;
    box-shadow: -8px -1px 10px rgba(255, 255, 255, 0.1);
    color: var(--tg-white);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}

.about-img-two .main-img .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    animation: pulse-border 1500ms ease-out infinite;
}

.about-img-two img:nth-child(2) {
    border: 9px solid #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    transform: rotate(-2.71deg);
    position: absolute;
    left: 40px;
    bottom: 70px;
}

.about-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.about-shape-wrap img:nth-child(1) {
    right: 41%;
    top: 13%;
    animation: alltuchtopdown 8s infinite linear;
}

.about-shape-wrap img:nth-child(2) {
    right: 0;
    bottom: 0;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@-webkit-keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes alltuchtopdown {
    0% {
        transform: rotateX(0deg) translateY(0px);
    }

    50% {
        transform: rotateX(0deg) translateY(-50px);
    }

    100% {
        transform: rotateX(0deg) translateY(0px);
    }
}

/* about-three */
.about-area-three {
    position: relative;
    padding: 80px 0;
}

.about-img-wrap-three {
    position: relative;
    text-align: center;
    padding-bottom: 90px;
}

.about-img-wrap-three img:nth-child(1) {
    border-radius: 100px 0 0 0;
}

.about-img-wrap-three img:nth-child(2) {
    border-radius: 0 50px 0 0;
    border: 8px solid var(--tg-white);
    position: absolute;
    right: 0;
    bottom: 25px;
    z-index: 2;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.about-img-wrap-three .experience-wrap {
    width: 506px;
    display: flex;
    align-items: center;
    text-align: left;
    background: var(--tg-primary-color);
    border-radius: 0px 0px 0px 60px;
    padding: 40px 195px 50px 45px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.about-img-wrap-three .experience-wrap .title {
    margin-bottom: 0;
    text-align: center;
    font-size: 64px;
    line-height: .8;
    color: var(--tg-white);
    position: relative;
    padding-right: 13px;
    margin-right: 13px;
}

.about-img-wrap-three .experience-wrap .title span {
    display: block;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.1em;
    margin-top: 5px;
}

.about-img-wrap-three .experience-wrap .title::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background: var(--tg-white);
}

.about-img-wrap-three .experience-wrap p {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--tg-white);
    line-height: 1.4;
    font-family: var(--tg-heading-font-family);
}

.section-title-two span {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
    color: var(--tg-primary-color);
    background: var(--tg-lavender);
    border-radius: 50px;
    padding: 4px 13px;
    margin-bottom: 12px;
}

.section-title-two .title {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 0;
}

.about-content-three p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
    width: 90%;
}

.about-content-three p.info-one {
    margin-bottom: 25px;
}

.about-list-two .list-wrap {
    margin-bottom: 20px;
}

.about-list-two .list-wrap li {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: baseline;
    text-transform: capitalize;
    color: var(--tg-secondary-color);
    margin-bottom: 14px;
}

.about-list-two .list-wrap li:last-child {
    margin-bottom: 0;
}

.about-list-two .list-wrap li i {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 12px;
    margin-right: 8px;
    flex: 0 0 auto;
}

.about-author-info {
    display: flex;
    align-items: center;
    margin-top: 35px;
}

.about-author-info .thumb {
    border-radius: 50%;
    border: 3px solid var(--tg-white);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    width: 70px;
    flex: 0 0 auto;
    margin-right: 15px;
}

.about-author-info .content .title {
    font-size: 20px;
    margin-bottom: 5px;
}

.about-author-info .content span {
    font-weight: 500;
    font-size: 14px;
    display: block;
    line-height: 1;
}

.about-author-info .signature {
    margin-left: 20px;
}

.about-shape-wrap-two img {
    position: absolute;
    z-index: -1;
}

.about-shape-wrap-two img:nth-child(1) {
    left: 15%;
    bottom: 27%;
    animation: alltuchtopdown 8s infinite linear;
}

.about-shape-wrap-two img:nth-child(2) {
    left: 40%;
    top: 5%;
}

.about-shape-wrap-two img:nth-child(3) {
    right: 0;
    bottom: 0;
}

/* about-four */
.about-content-four>p {
    color: var(--tg-paragraph-color);
    margin-bottom: 35px;
}

.about-list-three .list-wrap li {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 13px;
}

.about-list-three .list-wrap li:last-child {
    margin-bottom: 0;
}

.about-list-three .list-wrap li .icon {
    position: relative;
    font-size: 50px;
    line-height: 0;
    color: var(--tg-primary-color);
    margin-right: 15px;
}

.about-list-three .list-wrap li .icon::before {
    content: "";
    position: absolute;
    left: -13px;
    top: -15px;
    width: 46px;
    height: 46px;
    background: var(--tg-lavender);
    border-radius: 50%;
    z-index: -1;
}

.about-list-three .list-wrap li .icon i {
    transform: rotateY(0);
    transition: .3s linear;
    display: block;
}

.about-list-three .list-wrap li:hover .icon i {
    transform: rotateY(180deg);
}

.about-list-three .content .title {
    font-size: 20px;
    margin-bottom: 10px;
}

.about-list-three .content p {
    margin-bottom: 0;
    width: 75%;
    color: var(--tg-paragraph-color);
}

.about-img-wrap-four {
    position: relative;
    margin-right: -30px;
}

.mask-img-wrap {
    -webkit-mask-image: url(../img/images/mask_img.png);
    mask-image: url(../img/images/mask_img.png);
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    z-index: -1;
    height: 558px;
    width: 633px;
    margin-left: auto;
}


.about-img-wrap-four .img-two {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: absolute;
    left: 10%;
    bottom: 14%;
}

.about-shape-wrap-three img {
    position: absolute;
    z-index: -1;
}

.about-shape-wrap-three img:nth-child(1) {
    left: 0;
    top: 6%;
    animation: ribbonRotate 15s infinite linear;
}

.about-shape-wrap-three img:nth-child(2) {
    left: 4%;
    bottom: 7%;
    animation: alltuchtopdown 8s infinite linear;
}

.about-shape-wrap-three img:nth-child(3) {
    right: 23%;
    top: -2%;
    animation: rotateme 8s infinite linear;
}

.about-img-wrap-four .icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 8%;
    top: 21%;
    border-radius: 50%;
    background: var(--tg-blue);
    border: 5px solid var(--tg-white);
    color: var(--tg-white);
    font-size: 50px;
    line-height: 0;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

/* about-five */
.about-area-five {
    position: relative;
    padding: 120px 0;
}

.about-img-wrap-five {
    position: relative;
    padding-bottom: 30px;
}

.about-img-wrap-five::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    width: 380px;
    background: var(--tg-primary-color);
    z-index: -1;
}

.about-img-wrap-five img:nth-child(1) {
    clip-path: polygon(0 0, 85% 0, 100% 15%, 100% 100%, 0% 100%, 0 100%);
}

.about-img-wrap-five img:nth-child(2) {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: -25%;
    top: 14%;
}

.about-img-wrap-five .experience-wrap {
    background: var(--tg-primary-color);
    max-width: 200px;
    padding: 36px 20px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.about-img-wrap-five .experience-wrap .title {
    margin-bottom: 0;
    color: var(--tg-white);
    font-size: 60px;
    text-align: center;
    line-height: 1;
}

.about-img-wrap-five .experience-wrap .title span {
    display: block;
    font-weight: 500;
    font-size: 24px;
}

.about-content-five>p {
    margin-bottom: 50px;
    color: var(--tg-paragraph-color);
    width: 85%;
}

.about-content-five {
    margin-left: 55px;
    width: 85%;
}

.about-content-five .about-list {
    width: 60%;
    flex: 0 0 auto;
}

.about-content-five .about-list .list-wrap {
    gap: 25px 0;
    margin-bottom: 0;
}

.about-content-five .about-list .list-wrap li {
    color: var(--tg-secondary-color);
    width: 100%;
}

.about-content-five .about-list .list-wrap li i {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 12px;
    margin-right: 8px;
    flex: 0 0 auto;
}

.about-content-five .about-content-bottom {
    display: flex;
    align-items: center;
}

.about-success-wrap .list-wrap li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.about-success-wrap .list-wrap li:last-child {
    margin-bottom: 0;
}

.about-success-wrap .list-wrap li .icon {
    font-size: 60px;
    line-height: 0;
    color: var(--tg-primary-color);
    flex: 0 0 auto;
    margin-right: 20px;
}

.about-success-wrap .list-wrap li .content .count {
    font-weight: 800;
    font-size: 50px;
    line-height: .8;
    margin-bottom: 5px;
}

.about-success-wrap .list-wrap li .content p {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--tg-secondary-color);
    font-family: var(--tg-heading-font-family);
    line-height: 1;
}

.about-shape-five img {
    position: absolute;
    left: 41%;
    bottom: 35%;
    z-index: -1;
    animation: alltuchtopdown 8s infinite linear;
}

/* about-six */
.about-area-six {
    padding: 0 0 120px;
    position: relative;
    overflow: hidden;
}

.about-area-six::before {
    content: "";
    position: absolute;
    width: 310px;
    height: 310px;
    background: #FAE7FF;
    filter: blur(200px);
    right: 26%;
    top: 16%;
    z-index: -1;
}

.about-img-six {
    margin-left: -75px;
    position: relative;
    padding-top: 40px;
}

.about-img-six img:nth-child(1) {
    max-width: unset;
    position: unset;
}

.about-img-six img {
    position: absolute;
    z-index: -1;
}

.about-img-six img:nth-child(2) {
    left: 0;
    bottom: -40px;
}

.about-img-six img:nth-child(3) {
    left: 19%;
    top: 14%;
    animation: alltuchtopdown 8s infinite linear;
}

.about-content-six {
    width: 88%;
}

.about-content-six p {
    margin-bottom: 30px;
}

.about-content-six .about-list .list-wrap {
    margin-bottom: 45px;
}

.about-content-six .about-list .list-wrap li {
    color: var(--tg-secondary-color);
}

.about-content-six .about-list .list-wrap li i {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 12px;
    margin-right: 8px;
    flex: 0 0 auto;
}

/* about-seven */
.about-content-seven {
    width: 88%;
}

.about-content-seven>p {
    margin-bottom: 25px;
}

.success-wrap-two {
    margin-bottom: 25px;
}

.success-wrap-two .list-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.success-wrap-two .list-wrap li {
    background: var(--tg-lavender);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px 25px;
    min-width: 240px;
    transition: .3s;
}

.success-wrap-two .list-wrap li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 28px;
    border-radius: 3px;
    background: var(--tg-primary-color);
}

.success-wrap-two .list-wrap li:hover {
    transform: translateY(-5px);
}

.success-wrap-two .list-wrap li .icon {
    font-size: 50px;
    flex: 0 0 auto;
    line-height: 0;
    color: var(--tg-primary-color);
    margin-right: 18px;
}

.success-wrap-two .list-wrap li .content .count {
    font-weight: 800;
    font-size: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    line-height: .8;
}

.success-wrap-two .list-wrap li .content p {
    margin-bottom: 0;
    line-height: 1;
}

.about-img-seven-wrap {
    position: relative;
    padding-bottom: 90px;
}

.about-img-seven-wrap img:nth-child(1) {
    border-radius: 10px;
}

.about-img-seven-wrap img:nth-child(2) {
    position: absolute;
    right: 50px;
    bottom: 0;
    border: 8px solid var(--tg-white);
    border-radius: 10px;
    z-index: 3;
}

.about-img-seven-wrap .shape {
    position: absolute;
    left: 33%;
    bottom: -8%;
    z-index: -1;
    animation: alltuchtopdown 8s infinite linear;
}

.about-img-seven-wrap .experience-wrap {
    opacity: 0;
    /* Make div invisible initially */
    animation-name: appear;
    animation-duration: 1s;
    /* Or however long you want the fade in to take */
    animation-delay: 1s;
    /* Set this to the delay you want before the div appears */
    animation-fill-mode: forwards;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 8px solid #FFFFFF;
    border-radius: 19px 63px 24px 0px;
    max-width: 197px;
    min-height: 384px;
    background: #121266;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px;
    text-align: center;
}

.about-img-seven-wrap .experience-wrap .title {
    color: var(--tg-white);
    margin-bottom: 10px;
    font-size: 60px;
    line-height: .8;
}

.about-img-seven-wrap .experience-wrap p {
    margin-bottom: 0;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
}

/* about-eight */
.about-img-eight img {
    border-radius: 15px;
}

.about-content-eight>p {
    margin-bottom: 35px;
}

.about-content-eight .about-content-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
}

.about-content-eight .about-content-inner .list-wrap {
    width: 58%;
}

.about-content-eight .about-content-inner .list-wrap li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
}

.about-content-eight .about-content-inner .list-wrap li:last-child {
    margin-bottom: 0;
}

.about-content-eight .about-content-inner .list-wrap li .icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-lavender);
    color: var(--tg-primary-color);
    font-size: 40px;
    line-height: 0;
    position: relative;
    border-radius: 15px;
    flex: 0 0 auto;
    margin-right: 20px;
}

.about-content-eight .about-content-inner .list-wrap li .icon::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 16px;
    height: 3px;
    background: var(--tg-primary-color);
    border-radius: 3px;
}

.about-content-eight .about-content-inner .content .title {
    font-size: 20px;
    margin-bottom: 8px;
}

.about-content-eight .about-content-inner .content p {
    margin-bottom: 0;
}

.about-content-eight .right-slide-img {
    margin-left: auto;
}

.about-content-eight .right-slide-img img {
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.about-content-eight .about-content-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px 40px;
}

.about-content-eight .about-content-bottom .about-author-info {
    margin-top: 0;
}

/* about-ten */
.about-area-ten {
    padding: 120px 0;
    position: relative;
}

.about-img-wrap-ten {
    padding-left: 120px;
}

.about-img-wrap-ten img:nth-child(2) {
    left: 0;
}

.about-area-ten .about-shape-five img {
    left: 46%;
}

.about-content-five.about-content-ten {
    margin-left: 80px;
    width: auto;
    margin-right: 30px;
}

.about-content-ten .about-success-wrap .list-wrap {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 45px;
}

.about-content-ten .about-success-wrap .list-wrap li {
    margin-bottom: 0;
}

.about-content-five.about-content-ten>p {
    margin-bottom: 20px;
    width: 100%;
}

/* about-eleven */
.about-area-eleven {
    position: relative;
    padding: 120px 0 0;
}

.about-area-eleven::before {
    content: "";
    position: absolute;
    left: 20%;
    top: 32%;
    width: 300px;
    height: 300px;
    background: #FAE7FF;
    filter: blur(200px);
    z-index: -1;
}

.about-content-eleven {
    width: 85%;
}

.about-content-eleven p {
    margin-bottom: 25px;
}

.about-content-eleven .about-list-two .list-wrap {
    margin-bottom: 40px;
}

.about-img-wrap-eleven {
    position: relative;
    text-align: center;
    padding-bottom: 30px;
}

.about-img-wrap-eleven .shape-one {
    position: absolute;
    left: -5%;
    top: -2%;
    z-index: -1;
    animation: alltuchtopdown 8s infinite linear;
}

.about-img-wrap-eleven .shape-two {
    position: absolute;
    left: -20px;
    bottom: 0;
    z-index: -2;
    max-width: unset;
}

/* about-twelve */
.about-area-twelve {
    padding: 140px 0 140px;
}

.about-img-six.about-img-twelve img:nth-child(2) {
    bottom: -50px;
}


/*=============================
	10. Overview
===============================*/
.overview-area {
    position: relative;
    overflow: hidden;
}

.overview-area::before {
    content: "";
    position: absolute;
    width: 480px;
    height: 480px;
    opacity: 0.2;
    filter: blur(75px);
    border-radius: 50%;
    background: #D5CEFF;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.overview-shape {
    right: 0;
    top: 0;
    width: 424px;
    height: 342px;
    position: absolute;
    z-index: -1;
}

.overview-img-wrap {
    text-align: center;
    position: relative;
}

.overview-img-wrap img:nth-child(1) {
    border-radius: 280px;
}

.overview-img-wrap img:nth-child(2) {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 30px;
}

.overview-img-wrap img:nth-child(3) {
    position: absolute;
    left: -50px;
    bottom: 32%;
    z-index: -1;
    animation: alltuchtopdown 8s infinite linear;
}

.overview-img-wrap .icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    position: absolute;
    right: 90px;
    top: 20px;
    border-radius: 50%;
    font-size: 50px;
    line-height: 0;
    color: var(--tg-white);
    border: 5px solid var(--tg-white);
}

.overview-content {
    width: 90%;
}

.overview-content>p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
}

.overview-content .info-one {
    margin-bottom: 15px;
}

.overview-content .info-two {
    margin-bottom: 30px;
}

.overview-content .content-bottom .list-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.overview-content .content-bottom .list-wrap li {
    border: 1.2px solid #D4DCED;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 28px 20px 28px 30px;
    min-width: 256px;
}

.overview-content .content-bottom .list-wrap li .icon {
    font-size: 50px;
    line-height: 0;
    color: var(--tg-primary-color);
    position: relative;
    margin-right: 40px;
}

.overview-content .content-bottom .list-wrap li .icon::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--tg-lavender);
    left: -10px;
    top: -12px;
    border-radius: 50%;
    z-index: -1;
}

.overview-content .content-bottom .list-wrap li .icon::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 38px;
    background: var(--tg-lavender);
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: -1;
}

.overview-content .content-bottom .list-wrap li .content .count {
    font-size: 36px;
    margin-bottom: 3px;
    line-height: .8;
    display: flex;
    align-items: center;
}

.overview-content .content-bottom .list-wrap li .content p {
    margin-bottom: 0;
}

/* overview-two */
.overview-area-two {
    position: relative;
    padding: 0 0 120px;
}

.overview-area-two::before {
    content: "";
    position: absolute;
    left: 114px;
    top: 0;
    width: 600px;
    height: 600px;
    background: #D5CEFF;
    opacity: 0.2;
    filter: blur(75px);
    z-index: -1;
}

.overview-img-two {
    position: relative;
}

.mask-img-two {
    -webkit-mask-image: url(../img/images/mask_img02.png);
    mask-image: url(../img/images/mask_img02.png);
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    z-index: -1;
    height: 534px;
    width: 556px;
}

.mask-img-two img {
    max-width: unset;
}

.overview-img-two .img-two {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: absolute;
    left: -13%;
    bottom: 21%;
}

.overview-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.overview-shape-wrap img:nth-child(1) {
    right: 7%;
    top: 15%;
    animation: float-bob-x 4s infinite linear;
}

.overview-shape-wrap img:nth-child(2) {
    right: 6%;
    top: 25%;
}

.overview-content-two {
    margin-left: 45px;
}

.overview-content-two p {
    margin-bottom: 30px;
    color: var(--tg-paragraph-color);
}

/*=============================
	11. Features
===============================*/
.features-area {
    margin-top: -150px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
}

.features-item {
    border: 1px solid var(--tg-steel-blue);
    border-radius: 10px;
    padding: 45px 35px;
    margin-bottom: 40px;
    background: var(--tg-white);
    position: relative;
}

.fix-height {
    height: 17rem;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.flip_card {
    animation: pulse 2s infinite;
}

.flip_card {
    margin: 0 auto;
    padding-top: 50px;
    perspective: 150rem;
    position: relative;
    height: 25rem;
    max-width: 400px;
}

.flip_card:hover {
    animation: none;
}

.card-side {
    border-radius: 15px;
    transition: all 0.8s ease;
    backface-visibility: hidden;
    position: absolute;
}

.card-side.back {
    transform: rotateY(-180deg);
}


.flip_card:hover .card-side.front {
    transform: rotateY(180deg);
}

.flip_card:hover .card-side.back {
    transform: rotateY(0deg);
}


.features-item::before {
    content: "";
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: -5px;
    background: var(--tg-gray);
    border: 1px solid var(--tg-steel-blue);
    border-radius: 8px;
    height: 70px;
    z-index: -1;
    transition: .3s ease-in;
}

.features-item::after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -10px;
    background: #DFE5F0;
    border: 1px solid var(--tg-steel-blue);
    border-radius: 8px;
    height: 70px;
    z-index: -2;
    transition: .3s ease-in;
}

.features-item:hover::after,
.features-item:hover::before {
    bottom: 3px;
}

.features-content .content-top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.features-content .content-top .icon {
    font-size: 50px;
    line-height: 0;
    position: relative;
    color: var(--tg-primary-color);
    z-index: 1;
    margin-right: 15px;
}

.features-content .content-top .icon::before {
    content: "";
    position: absolute;
    left: -13px;
    top: -15px;
    width: 46px;
    height: 46px;
    background: var(--tg-lavender);
    z-index: -1;
    border-radius: 50%;
    transition: .3s ease-in;
}

.features-item:hover .features-content .content-top .icon::before {
    left: 0;
    top: 0;
}

.features-content .content-top .title {
    font-size: 22px;
    margin-bottom: 0;
}

.features-content p {
    margin-bottom: 0;
    color: var(--tg-gray-four);
}

/* features-two */
.features-item-wrap {
    border-bottom: 1px solid var(--tg-lavender);
    padding-bottom: 50px;
}

.features-item-two {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

.features-icon-three,
.features-icon-two {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: var(--tg-lavender);
    flex: 0 0 auto;
    color: var(--tg-primary-color);
    font-size: 40px;
    line-height: 0;
    position: relative;
    margin-right: 20px;
}

.features-icon-three::before,
.features-icon-two::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: var(--tg-primary-color);
    top: 0;
    width: 16px;
    height: 3px;
    border-radius: 3px;
}

.features-icon-two i {
    transform: rotateY(0);
    transition: .3s linear;
}

.features-item-two:hover .features-icon-two i {
    transform: rotateY(180deg);
}

.features-content-two .title {
    font-size: 20px;
    margin-bottom: 10px;
}

.features-content-two p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
    width: 80%;
}


/* features-three */
.features-area-three {
    position: relative;
    padding: 120px 0 90px;
    overflow: hidden;
}

.features-area-three::before {
    content: "";
    position: absolute;
    left: -127px;
    top: 90px;
    width: 530px;
    height: 530px;
    background: #CC3DE3;
    opacity: 0.05;
    filter: blur(75px);
    border-radius: 50%;
    z-index: -1;
}

.features-area-three::after {
    content: "";
    position: absolute;
    right: -120px;
    top: 80px;
    width: 530px;
    height: 530px;
    background: #5E3DE3;
    opacity: 0.05;
    filter: blur(75px);
    z-index: -1;
}

.features-item-three {
    background: var(--tg-white);
    border: 1px solid var(--tg-border-color);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    transition: .3s linear;
    z-index: 1;
    margin-bottom: 30px;
}

.features-item-three:hover {
    background: var(--tg-primary-color);
    border-color: var(--tg-primary-color);
    box-shadow: 0px 20px 50px rgba(3, 74, 218, 0.29);
}

.features-item-three .features-icon-three {
    margin: 0 auto;
    margin-bottom: 25px;
    transition: .3s;
    width: 65px;
    height: 65px;
}

.features-item-three:hover .features-icon-three {
    background: var(--tg-white);
}

.features-content-three .title {
    font-weight: 700;
    font-size: 24px;
    transition: .3s;
}

.features-content-three p {
    margin-bottom: 17px;
    color: var(--tg-paragraph-color);
    transition: .3s;
}

.features-item-three:hover .features-content-three p,
.features-item-three:hover .features-content-three .title {
    color: var(--tg-white);
}

.features-content-three .link-btn {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: var(--tg-paragraph-color);
}

.features-content-three .link-btn img {
    margin-left: 10px;
    transition: .3s;
}

.features-item-three:hover .features-content-three .link-btn {
    color: var(--tg-white);
}

.features-item-three:hover .features-content-three .link-btn img {
    filter: brightness(0) invert(1);
}

.features-item-wrap-two .row [class*="col-"]:nth-child(even) .features-item-three {
    margin-top: 20px;
}

/* features-four */
.features-item-wrap-four {
    background: var(--tg-white);
    box-shadow: 0px 4px 50px rgba(233, 233, 243, 0.5);
    border-radius: 10px;
    padding: 65px 50px 30px;
    margin-top: -90px;
    position: relative;
    z-index: 1;
}

.features-item-four {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.features-icon-four {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-right: 20px;
    color: var(--tg-primary-color);
    font-size: 40px;
    position: relative;
    line-height: 0;
    border-radius: 14px;
    background: var(--tg-lavender);
}

.features-icon-four::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 16px;
    background: var(--tg-primary-color);
    border-radius: 2px;
}

.features-icon-four i {
    transition: .3s linear;
    transform: rotateY(0);
}

.features-item-four:hover .features-icon-four i {
    transform: rotateY(180deg);
}

.features-content-four .title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
}

.features-content-four p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
}


/* features-five */
.features-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0px;
    z-index: 1;
    position: relative;
    overflow: hidden;
}

.features-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.features-shape-wrap img:nth-child(1) {
    right: 9%;
    top: 10%;
    animation: float-bob-x 4s infinite linear;
}

.features-shape-wrap img:nth-child(2) {
    left: 8%;
    bottom: 10%;
    animation: animationFramesOne 18s linear infinite;
}


/* features-six */
.features-item-wrap-six {
    margin-top: 0;
    border: 1px solid #E7EAF1;
    z-index: 3;
    position: relative;
}

/* features-seven */
.features-area-seven {
    background: var(--tg-section-background);
    padding: 230px 0 120px;
    margin-top: -110px;
    position: relative;
    z-index: 1;
}

.features-area-seven::before {
    content: "";
    position: absolute;
    left: 40%;
    bottom: 70px;
    width: 390px;
    height: 390px;
    background: #FF6AE7;
    opacity: 0.1;
    filter: blur(75px);
    z-index: -1;
}

.features-content-seven {
    width: 90%;
}

.features-content-seven p {
    margin-bottom: 0;
}

.features-progress-wrap {
    width: 90%;
}

.inner-features-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

/*=============================
	12. Choose
===============================*/
.choose-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.choose-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #001641;
    opacity: 0.8;
    z-index: -1;
}

.choose-shape {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
}

.section-title-two.white-title .title {
    color: var(--tg-white);
}

.choose-content {
    width: 90%;
}

.choose-content p {
    margin-bottom: 40px;
    color: #D0D0DD;
}

.choose-content .play-btn {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}

.choose-content .play-btn:hover {
    color: var(--tg-primary-color);
}

.choose-content .play-btn i {
    width: 82px;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 20px;
    margin-right: 35px;
    position: relative;
}

.choose-content .play-btn i::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    animation: pulse-border 1500ms ease-out infinite;
}

.skill-wrap {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 70px 60px;
    background: var(--tg-white);
    margin-left: 100px;
}

.skill-wrap .section-title-two .title {
    font-size: 36px;
}

.skill-wrap>p {
    margin-bottom: 25px;
    color: var(--tg-paragraph-color);
}

.progress-wrap {
    overflow: hidden;
    padding-top: 5px;
}

.progress-item {
    margin-bottom: 25px;
}

.progress-item:last-child {
    margin-bottom: 0;
}

.progress-item .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--tg-paragraph-color);
}

.progress-item .progress {
    height: 8px;
    background-color: var(--tg-lavender);
    border-radius: 8px;
    overflow: inherit;
}

.progress-item .progress-bar {
    background-color: var(--tg-primary-color);
    position: relative;
    overflow: inherit;
    border-radius: 8px;
}

.progress-item .progress-bar span {
    display: block;
    position: absolute;
    right: 3px;
    bottom: 20px;
    color: var(--tg-white);
    line-height: 1;
    font-family: var(--tg-heading-font-family);
    font-weight: 700;
    font-size: 11px;
    border-radius: 2px;
    background: var(--tg-primary-color);
    padding: 4px 7px;
}

.progress-item .progress-bar span::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 8px;
    height: 8px;
    background: var(--tg-primary-color);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}

/* choose-two */
.choose-area-two {
    background: #001641;
    padding: 120px 0 205px;
    position: relative;
    z-index: 1;
}

.choose-img-two {
    position: relative;
    z-index: 1;
}

.choose-img-two img:nth-child(2) {
    position: absolute;
    right: 18%;
    top: 10%;
    z-index: -1;
}

.choose-img-two img:nth-child(3) {
    position: absolute;
    right: 14%;
    top: 19%;
    z-index: -2;
}

.choose-content-two {
    width: 90%;
}

.choose-content-two>p {
    color: #D0D0DD;
    margin-bottom: 30px;
    width: 90%;
}

.choose-circle-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.circle-item {
    position: relative;
    width: 160px;
}

.circle-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
}

.circle-content .percentage {
    display: block;
    font-weight: 800;
    font-size: 36px;
    line-height: 1;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    margin-bottom: 5px;
}

.circle-content p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
    color: var(--tg-white);
    line-height: 1.2;
}

/* choose-three */
.choose-area-three {
    background: var(--tg-secondary-color);
    position: relative;
    padding: 120px 0;
    overflow: hidden;
}

.choose-content-three {
    margin-right: 65px;
}

.choose-content-three>p {
    margin-bottom: 35px;
    color: var(--tg-lavender)
}

.accordion-wrap-two .accordion-item {
    border-radius: 5px;
    background: var(--tg-primary-color);
    border: none;
    margin-bottom: 10px;
    overflow: hidden;
}

.accordion-wrap-two .accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-wrap-two .accordion-item:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion-wrap-two .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.accordion-wrap-two .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.accordion-wrap-two .accordion-button {
    padding: 16px 60px 16px 22px;
    font-weight: 600;
    font-size: 20px;
    color: var(--tg-white);
    background: #052462;
    position: relative;
}

.accordion-wrap-two .accordion-button:focus {
    box-shadow: none;
}

.accordion-wrap-two .accordion-button:not(.collapsed) {
    color: var(--tg-white);
    background-color: #1A66FF;
    box-shadow: none;
}

.accordion-wrap-two .accordion-button::after {
    content: "\f102";
    width: auto;
    height: auto;
    background-image: none;
    font-family: "flaticon";
    font-size: 16px;
    position: absolute;
    right: 25px;
    top: 18px;
    color: var(--tg-primary-color);
}

.accordion-wrap-two .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: rotate(-180deg);
    color: var(--tg-white);
}

.accordion-wrap-two .accordion-body {
    padding: 20px 40px 30px 22px;
}

.accordion-wrap-two .accordion-body p {
    margin-bottom: 0;
    color: var(--tg-white);
}

.choose-img-three {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
}

.choose-img-three img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}



/*=============================
	13. Services
===============================*/
.section-title.white-title .sub-title {
    color: var(--tg-lavender);
}

.section-title.text-center .sub-title::before {
    left: 50%;
    transform: translateX(-50%);
}

.section-title.white-title .title {
    color: var(--tg-white);
}

.section-title p {
    margin-bottom: 0;
    margin-top: 16px;
    color: var(--tg-gray-four);
}

.section-title.white-title p {
    color: var(--tg-lavender);
}

.services-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
}

.services-item {
    background: var(--tg-white);
    border-radius: 10px;
    padding: 25px 35px 35px;
    margin-bottom: 30px;
}

.services-content .content-top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.services-content .content-top .icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-lavender);
    font-size: 30px;
    color: var(--tg-primary-color);
    line-height: 0;
    margin-right: 15px;
    transition: .3s ease-in;
}

.services-item:hover .services-content .content-top .icon {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.services-content .content-top .title {
    margin-bottom: 0;
    font-size: 24px;
    width: 70%;
}

.services-thumb {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 25px;
}

.services-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--tg-primary-color);
    opacity: 0;
    transition: .3s linear;
}

.services-item:hover .services-thumb::before {
    opacity: .8;
}

.services-thumb .btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border-color: var(--tg-white);
    margin-top: -100px;
    opacity: 0;
    visibility: hidden;
    color: var(--tg-white);
}

.services-thumb .btn::before {
    background: var(--tg-white);
}

.services-thumb .btn::after {
    color: var(--tg-primary-color);
}

.services-thumb .btn:hover {
    color: var(--tg-primary-color);
}

.services-item:hover .services-thumb .btn {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.services-content .list-wrap li {
    text-transform: capitalize;
    color: var(--tg-paragraph-color);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.services-content .list-wrap li:last-child {
    margin-bottom: 0;
}

.services-content .list-wrap li::before {
    content: "";
    width: 6px;
    height: 6px;
    background: var(--tg-paragraph-color);
    border-radius: 50px;
    margin-right: 10px;
    flex: 0 0 auto;
}

.services-active [class*="col-"] {
    padding: 0 15px;
}

.services-active .slick-dots {
    display: flex !important;
    padding: 0;
    margin: 0;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.services-active .slick-dots li {
    list-style: none;
    line-height: 0;
}

.services-active .slick-dots li button {
    text-indent: -9999999999px;
    padding: 0;
    border: 2px solid var(--tg-lavender);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: transparent;
}

.services-active .slick-dots li.slick-active button {
    border-color: var(--tg-primary-color);
    background: var(--tg-primary-color);
}

/* services-two */
.services-bg-two {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
}

.services-item-two {
    position: relative;
    margin-bottom: 30px;
}

.services-thumb-two {
    position: relative;
    border-radius: 8px;
    border: 7px solid var(--tg-white);
    box-shadow: 0px 10px 30px #CED2DD;
    overflow: hidden;
    z-index: 1;
}

.services-thumb-two::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, #121267 11.66%, rgba(0, 25, 76, 0) 65.89%);
}

.services-thumb-two::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, rgb(0 25 76 / 55%) 52.77%, rgba(0, 25, 76, 0) 100%, #121267 100%);
    transition: .3s linear;
    opacity: 0;
}

.services-item-two:hover .services-thumb-two::after {
    opacity: 1;
}

.services-thumb-two img {
    width: 100%;
}

.services-thumb-two .item-shape {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -50px;
    transition: .3s linear;
    opacity: 0;
}

.services-item-two:hover .services-thumb-two .item-shape {
    margin-top: 0;
    opacity: 1;
}

.services-content-two {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 25px;
    z-index: 2;
}

.services-content-two .icon {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    line-height: 0;
    background: rgb(255 255 255 / 20%);
    border-radius: 8px;
    color: var(--tg-white);
    margin-bottom: 15px;
    transition: .3s linear;
}

.services-item-two:hover .services-content-two .icon {
    background: var(--tg-primary-color);
}

.services-content-two .title {
    margin-bottom: 10px;
    font-size: 24px;
    color: var(--tg-white);
}

.services-content-two p {
    margin-bottom: 5px;
    color: var(--tg-lavender);
    display: none;
}


/* services-three */
.services-area-three {
    background: var(--tg-section-background);
    position: relative;
    z-index: 1;
    padding: 120px 0 90px;
}

.services-item-three {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.services-thumb-three {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.services-thumb-three img {
    transform: scale(1);
    transition: .3s ease-in-out;
    width: 100%;
}

.services-item-three:hover .services-thumb-three img {
    transform: scale(1.1);
}

.services-content-three {
    position: relative;
    background: var(--tg-white);
    border-radius: 0 0 10px 10px;
    padding: 60px 30px 50px;
}

.services-content-three .services-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    box-shadow: 0px 10px 15px rgba(0, 85, 255, 0.2);
    border-radius: 15px;
    color: var(--tg-white);
    font-size: 40px;
    line-height: 0;
    position: absolute;
    left: 30px;
    top: -35px;
}

.services-content-three .services-icon i {
    transition: .3s linear;
}

.services-item-three:hover .services-icon i {
    transform: rotateY(180deg);
}

.services-content-three .title {
    font-size: 22px;
    margin-bottom: 10px;
}

.services-content-three p {
    margin-bottom: 0;
}

.overlay-icon {
    position: absolute;
    font-size: 60px;
    right: 30px;
    bottom: -5px;
    line-height: 0;
    color: var(--tg-lavender);
}

.services-shape-four {
    top: 2%;
    position: absolute;
    right: 0;
    z-index: -1;
    opacity: .5;
}


/* services-four */
.services-area-four {
    padding: 120px 0 90px;
    position: relative;
    overflow: hidden;
}

.services-area-four::before {
    content: "";
    position: absolute;
    left: -30px;
    bottom: 8%;
    width: 390px;
    height: 390px;
    background: #E7F9FF;
    filter: blur(200px);
    z-index: -1;
}

.services-area-four::after {
    content: "";
    position: absolute;
    right: 80px;
    top: 12%;
    width: 350px;
    height: 350px;
    background: #F7E7FF;
    filter: blur(200px);
    z-index: -1;
}

.section-title-three .sub-title {
    font-size: 14px;
}

.section-title-three .title {
    font-size: 36px;
    font-weight: 800;
}

.section-title-three p {
    color: var(--tg-paragraph-color);
    width: 90%;
    margin: 12px auto 0;
}

.services-item-four {
    background: var(--tg-white);
    border: 1px solid #DDE5F6;
    border-radius: 30px;
    text-align: center;
    padding: 55px 50px 65px;
    margin-bottom: 30px;
    transition: .3s linear;
}

.services-item-four:hover {
    filter: drop-shadow(0px 14px 200px #E1E5ED);
}

.services-icon-four {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 35px;
    border-radius: 50%;
    line-height: 0;
    background: var(--tg-lavender);
    color: var(--tg-primary-color);
    font-size: 50px;
    transition: .3s linear;
}

.services-item-four:hover .services-icon-four {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    box-shadow: 0px 10px 15px rgba(0, 85, 255, 0.2);
}

.services-content-four .title {
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 15px;
}

.services-content-four p {
    margin-bottom: 25px;
}

/* services-five */
.inner-services-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
}

/* services-six */
.services-area-six {
    background: var(--tg-section-background);
    padding: 120px 0 90px;
}

/* services-seven */
.services-area-seven {
    background: var(--tg-section-background);
}


/* services-details */
.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



.services-details-area .row .col-71 {
    width: 71%;
    flex: 0 0 auto;
}

.services-details-area .row .col-29 {
    width: 29%;
    flex: 0 0 auto;
}

.services-details-thumb {
    margin-bottom: 20px;
}

.services-details-thumb img {
    border-radius: 10px;
    width: 100%;
}

.services-details-content .title {
    font-weight: 800;
    font-size: 36px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.services-details-content>p {
    margin-bottom: 15px;
}

.sd-inner-wrap {
    margin-top: 30px;
    margin-bottom: 25px;
}

.sd-inner-wrap .row .col-56 {
    width: 56.5%;
    flex: 0 0 auto;
}

.sd-inner-wrap .row .col-44 {
    width: 43.5%;
    flex: 0 0 auto;
}

.services-details-content .title-two {
    font-size: 30px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.sd-inner-wrap .content p {
    margin-bottom: 30px;
}

.sd-inner-wrap .content .list-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px 0;
}

.sd-inner-wrap .content .list-wrap li {
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    width: 50%;
}

.sd-inner-wrap .content .list-wrap li img {
    margin-right: 10px;
}

.sd-inner-wrap .thumb {
    position: relative;
}

.sd-inner-wrap .thumb img {
    width: 100%;
    border-radius: 10px;
}

.sd-inner-wrap .thumb .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
}

.sd-inner-wrap .thumb .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    animation: pulse-border 1500ms ease-out infinite;
}

.company-benefit-wrap {
    margin-top: 40px;
}

.company-benefit-wrap p {
    margin-bottom: 25px;
}

.accordion-wrap-three .accordion-item {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
}

.accordion-wrap-three .accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-wrap-three .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.accordion-wrap-three .accordion-button {
    position: relative;
    padding: 16px 50px 16px 25px;
    font-weight: 600;
    font-size: 20px;
    color: var(--tg-secondary-color);
    text-align: left;
    background-color: var(--tg-gray-two);
    font-family: var(--tg-heading-font-family);
    border: none;
}

.accordion-wrap-three .accordion-button:not(.collapsed) {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}

.accordion-wrap-three .accordion-button::after {
    content: "\f102";
    width: auto;
    height: auto;
    background-image: none;
    font-family: "flaticon";
    font-size: 16px;
    position: absolute;
    right: 25px;
    top: 18px;
    color: var(--tg-primary-color);
}

.accordion-wrap-three .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: rotate(-180deg);
    color: var(--tg-white);
}

.accordion-wrap-three .accordion-body {
    padding: 20px 40px 30px 30px;
    border: 1px solid #DFDFDF;
    border-top: none !important;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
}

.accordion-wrap-three .accordion-body p {
    margin-bottom: 0;
}

.accordion-wrap-three .accordion-button:focus {
    border-color: #DFDFDF;
    box-shadow: none;
}

.sd-inner-wrap-two {
    margin-top: 35px;
    margin-bottom: 50px;
}

.sd-inner-wrap-two .row .col-58 {
    width: 58.4%;
    flex: 0 0 auto;
}

.sd-inner-wrap-two .row .col-42 {
    width: 41.6%;
    flex: 0 0 auto;
}

.sd-inner-wrap-two .thumb img {
    border-radius: 10px;
}

.services-details-list .list-wrap li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.services-details-list .list-wrap li:last-child {
    margin-bottom: 0;
}

.services-details-list .list-wrap li .icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-lavender);
    color: var(--tg-primary-color);
    border-radius: 14px;
    position: relative;
    font-size: 40px;
    line-height: 0;
    flex: 0 0 auto;
    margin-right: 20px;
}

.services-details-list .list-wrap li .icon::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 16px;
    height: 3px;
    background: var(--tg-primary-color);
    border-radius: 3px;
}

.services-details-list .list-wrap li .content .title {
    margin-bottom: 8px;
    font-size: 20px;
    text-transform: capitalize;
}

.services-details-list .list-wrap li .content p {
    margin-bottom: 0;
}

.industrial-working-wrap p {
    margin-bottom: 25px;
}

.industrial-working-wrap .info-two {
    margin-bottom: 0;
}

.industrial-working-wrap img {
    border-radius: 10px;
    margin-bottom: 25px;
}

.services-details-list-two {
    margin-right: 15px;
}

.services-details-list-two .list-wrap li {
    border: 1px solid #DADFEE;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    padding: 25px 30px;
    background: var(--tg-white);
    margin-bottom: 20px;
}

.services-details-list-two .list-wrap li:last-child {
    margin-bottom: 0;
}

.services-details-list-two .list-wrap li .icon {
    font-size: 35px;
    line-height: 0;
    color: var(--tg-primary-color);
    flex: 0 0 auto;
    margin-right: 10px;
    line-height: 0;
}

.services-details-list-two .list-wrap li .content .title {
    font-size: 22px;
    margin-bottom: 10px;
}

.services-details-list-two .list-wrap li .content p {
    margin-bottom: 0;
}

.sd-inner-wrap-three .col-43 {
    width: 43%;
    flex: 0 0 auto;
}

.sd-inner-wrap-three .col-57 {
    width: 57%;
    flex: 0 0 auto;
}

.sd-inner-wrap-three {
    margin-bottom: 50px;
    margin-top: 40px;
}

.sd-inner-wrap-four .row .col-55 {
    width: 55%;
    flex: 0 0 auto;
}

.sd-inner-wrap-four .row .col-45 {
    width: 45%;
    flex: 0 0 auto;
}

.sd-inner-wrap-four .thumb img {
    border-radius: 10px;
}

.sd-inner-wrap-four .content .list-wrap li {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    text-transform: capitalize;
    font-family: var(--tg-heading-font-family);
    margin-bottom: 15px;
}

.sd-inner-wrap-four .content .list-wrap li:last-child {
    margin-bottom: 0;
}

.sd-inner-wrap-four .content .list-wrap li img {
    margin-right: 10px;
}

.sd-inner-wrap-four {
    margin-bottom: 40px;
}

.services-details-list-three .list-wrap {
    display: flex;
    align-items: center;
    gap: 25px;
}

.services-details-list-three .list-wrap li {
    display: flex;
    align-items: flex-start;
    border: 1px solid #DADFEE;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: var(--tg-white);
    padding: 25px 30px;
}

.services-details-list-three .list-wrap li .icon {
    font-size: 50px;
    line-height: 0;
    color: var(--tg-primary-color);
    margin-right: 15px;
}

.services-details-list-three .list-wrap li .content .title {
    font-size: 22px;
    margin-bottom: 10px;
}

.services-details-list-three .list-wrap li .content p {
    margin-bottom: 0;
}

.services-details-list-three {
    margin-bottom: 30px;
}

.services-details-thumb-two {
    margin-bottom: 20px;
    margin-top: 25px;
}

.services-details-thumb-two img {
    border-radius: 10px;
}

.speed-optimized-wrap {
    margin-top: 50px;
    margin-bottom: 45px;
}

.speed-optimized-wrap p {
    margin-bottom: 0;
}

.sd-inner-wrap-five .row .col-50 {
    width: 50%;
    flex: 0 0 auto;
}

.sd-inner-wrap-five .left-side-content {
    margin-right: 30px;
}

.sd-inner-wrap-five .left-side-content p {
    margin-bottom: 0;
}

.sd-inner-wrap-five .right-side-content p {
    margin-bottom: 15px;
}

.sd-inner-wrap-five .right-side-content .list-wrap li {
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-weight: 600;
    font-size: 18px;
    color: var(--tg-secondary-color);
    font-family: var(--tg-heading-font-family);
    margin-bottom: 15px;
}

.sd-inner-wrap-five .right-side-content .list-wrap li:last-child {
    margin-bottom: 0;
}

.sd-inner-wrap-five .right-side-content .list-wrap li img {
    margin-right: 10px;
}

/* services-sidebar */
.services-sidebar {
    padding-right: 20px;
}

.services-cat-list .list-wrap li {
    margin-bottom: 10px;
}

.services-cat-list .list-wrap li:last-child {
    margin-bottom: 0;
}

.services-cat-list .list-wrap li a {
    border: 1px solid #E0E6F2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px;
    background: var(--tg-white);
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-secondary-color);
    text-transform: capitalize;
}

.services-cat-list .list-wrap li a i {
    margin-left: 10px;
    font-size: 22px;
    line-height: 0;
    color: var(--tg-gray-four);
    transition: all 0.3s ease-out 0s;
}

.services-cat-list .list-wrap li.active a,
.services-cat-list .list-wrap li a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
}

.services-cat-list .list-wrap li.active a i,
.services-cat-list .list-wrap li a:hover i {
    color: var(--tg-white);
}

.services-widget {
    background: var(--tg-section-background);
    border-radius: 10px;
    padding: 30px 30px 35px;
    margin-bottom: 30px;
}

.services-widget:last-child {
    margin-bottom: 0;
}

.services-widget .sw-title {
    margin-bottom: 15px;
    position: relative;
    font-size: 24px;
    text-transform: capitalize;
    padding-bottom: 10px;
}

.services-widget .sw-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    background: var(--tg-primary-color);
    border-radius: 4px;
}

.services-brochure-wrap p {
    margin-bottom: 15px;
}

.services-brochure-wrap .download-btn {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-secondary-color);
    border: 1px solid #E0E6F2;
    border-radius: 5px;
    background: var(--tg-white);
    padding: 13px 20px;
    margin-bottom: 10px;
}

.services-brochure-wrap .download-btn i {
    font-size: 16px;
    color: var(--tg-primary-color);
    margin-right: 10px;
    transition: all 0.3s ease-out 0s;
}

.services-brochure-wrap .download-btn:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
    border-color: var(--tg-primary-color);
}

.services-brochure-wrap .download-btn:hover i {
    color: var(--tg-white);
}

.services-brochure-wrap .download-btn:last-child {
    margin-bottom: 0;
}

.services-widget-form .form-grp {
    margin-bottom: 10px;
}

.services-widget-form .form-grp textarea,
.services-widget-form .form-grp input {
    width: 100%;
    border: 1px solid #E0E6F2;
    border-radius: 5px;
    background: var(--tg-white);
    padding: 13px 16px;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-paragraph-color);
    height: 50px;
    display: block;
}

.services-widget-form .form-grp textarea::placeholder,
.services-widget-form .form-grp input::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-gray-four);
}

.services-widget-form .form-grp textarea {
    min-height: 120px;
    max-height: 120px;
}

.submit-btn {
    border: none;
    background: var(--tg-primary-color);
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    color: var(--tg-white);
    text-transform: capitalize;
    border-radius: 5px;
    padding: 13px 20px;
    height: 50px;
}

.submit-btn:hover {
    background: var(--tg-secondary-color);
}

.services-sidebar-contact {
    background: var(--tg-paragraph-color);
    text-align: center;
}

.services-sidebar-contact .title {
    margin-bottom: 30px;
    color: var(--tg-white);
    font-size: 22px;
}

.services-sidebar-contact a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    border-radius: 6px;
    color: var(--tg-white);
    font-weight: 600;
    font-size: 18px;
    padding: 9px 22px;
}

.services-sidebar-contact a i {
    line-height: 0;
    margin-right: 5px;
}

.services-sidebar-contact a:hover {
    background: var(--tg-secondary-color);
}

.services-cat-list-two .list-wrap li a {
    position: relative;
    z-index: 1;
    background: #F8F8FA;
}

.services-cat-list-two .list-wrap li a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 30px;
    border-radius: 3px;
    background: var(--tg-primary-color);
    z-index: -1;
}


/*=============================
	14. Cta
===============================*/
.cta-area {
    margin-top: -105px;
}

.cta-inner-wrap-two,
.cta-inner-wrap {
    background-size: cover;
    background-position: center;
    padding: 70px;
    border-radius: 10px;
}

.cta-content {
    display: flex;
    align-items: center;
}

.cta-info-wrap {
    display: flex;
    align-items: center;
    width: 46%;
    flex: 0 0 auto;
    position: relative;
}

.cta-info-wrap::before {
    content: "";
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 74px;
    background: var(--tg-primary-color);
}

.cta-info-wrap .icon {
    font-size: 50px;
    color: var(--tg-white);
    line-height: 0;
    margin-right: 15px;
}

.cta-info-wrap .content span {
    display: block;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    line-height: 1;
    margin-bottom: 10px;
}

.cta-info-wrap .content a {
    font-weight: 700;
    font-size: 30px;
    display: inline-block;
    line-height: 1;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}

.cta-info-wrap .content a:hover {
    color: var(--tg-primary-color);
}

.cta-content .title {
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 0;
    color: var(--tg-white);
    line-height: 1.13;
}


/* cta-two */
.cta-inner-wrap-two .cta-info-wrap::before {
    background: var(--tg-white);
}

.cta-inner-wrap-two .cta-info-wrap a:hover {
    color: var(--tg-secondary-color);
}

.cta-inner-wrap-two .cta-btn .btn {
    border: 2px solid var(--tg-white);
    background: transparent;
    padding: 16px 30px;
}

.cta-inner-wrap-two .cta-btn .btn:hover {
    background: var(--tg-blue);
    border-color: var(--tg-blue);
}

/* cta-three */
.cta-area-three {
    background: #F8F8FA;
}

.cta-inner-wrap-three {
    margin-top: -85px;
    position: relative;
    z-index: 1;
}

.cta-info-wrap.cta-info-wrap-two .icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    background: var(--tg-white);
    font-size: 35px;
    color: var(--tg-primary-color);
    line-height: 0;
    margin-right: 15px;
}

.cta-info-wrap.cta-info-wrap-two .content a {
    font-size: 24px;
}

.cta-info-wrap.cta-info-wrap-two::before {
    display: none;
}

.cta-inner-wrap-three {
    background-size: cover;
    background-position: center;
    padding: 50px 45px;
    border-radius: 10px;
}

.cta-inner-wrap-three .btn-three {
    background: var(--tg-white);
    color: var(--tg-primary-color);
}

.cta-inner-wrap-three .btn-three::after {
    filter: unset;
}

.cta-inner-wrap-three .btn-three:hover {
    background: var(--tg-secondary-color);
    color: var(--tg-white);
}

.cta-inner-wrap-three .btn-three:hover::after {
    filter: brightness(0) invert(1);
}


.cta-area-four {
    margin-top: -100px;
}

.cta-area-five {
    position: relative;
    z-index: 3;
}


/*=============================
	15. Counter
===============================*/
.counter-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.counter-item {
    text-align: center;
    margin-bottom: 30px;
}

.counter-item .count {
    margin-bottom: 5px;
    font-weight: 800;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: .8;
}

.counter-item p {
    margin-bottom: 0;
}

.counter-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.counter-shape-wrap img:nth-child(1) {
    left: 10%;
    top: 25%;
    animation: animationFramesOne 15s linear infinite;
}

.counter-shape-wrap img:nth-child(2) {
    right: 10%;
    top: 45%;
    animation: animationFramesOne 20s linear infinite;
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}

.animationFramesOne {
    -webkit-animation: animationFramesOne 12s linear infinite;
    animation: animationFramesOne 12s linear infinite;
}


/* counter-two */
.counter-area-two {
    background: #F8F8FA;
    padding: 75px 0 45px;
}

.counter-item-two {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}

.counter-item-two::before {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 95px;
    background: #D5DDEC;
}

.counter-item-wrap .row [class*="col-"]:last-child .counter-item-two::before {
    display: none;
}

.counter-item-two .count {
    font-weight: 800;
    font-size: 60px;
    display: inline-flex;
    align-items: center;
    line-height: .8;
    margin-bottom: 5px;
    justify-content: center;
    position: relative;
}

.counter-item-two .count::before {
    content: "";
    position: absolute;
    left: -24px;
    top: -18px;
    width: 50px;
    height: 50px;
    background: #DCE7FF;
    border-radius: 50%;
}

.counter-item-two p {
    margin-bottom: 0;
}

/* counter-three */
.counter-item-three {
    border: 1.2px solid #D4DCED;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 28px 30px;
    margin-bottom: 30px;
    transform: translateY(0);
    transition: .3s linear;
}

.counter-item-three:hover {
    transform: translateY(-5px);
}

.counter-icon {
    font-size: 60px;
    line-height: 0;
    position: relative;
    color: var(--tg-primary-color);
    margin-right: 40px;
}

.counter-icon::before {
    content: "";
    position: absolute;
    left: -14px;
    top: -15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--tg-lavender);
    z-index: -1;
    transition: .3s linear;
}

.counter-icon::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 38px;
    background: var(--tg-lavender);
}

.counter-item-three:hover .counter-icon::before {
    left: 0;
    top: 0;
}

.counter-content .count {
    font-size: 36px;
    margin-bottom: 10px;
    line-height: .8;
    display: flex;
    align-items: center;
}

.counter-content p {
    margin-bottom: 0;
    line-height: 1.2;
    color: var(--tg-gray-four);
}


/* counter-four */
.counter-area-four {
    background: var(--tg-section-background);
    position: relative;
    padding: 120px 0px;
    overflow: hidden;
    z-index: 1;
}

.counter-area-four::before {
    content: "";
    position: absolute;
    right: 38%;
    bottom: 60px;
    width: 390px;
    height: 390px;
    background: #5E3DE3;
    opacity: 0.07;
    filter: blur(75px);
    z-index: -1;
}

.counter-content-four {
    width: 90%;
}

.counter-content-four p {
    margin-bottom: 0;
}

.counter-item-wrap-four .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -12px;
    gap: 24px 0;
}

.counter-item-wrap-four .list-wrap li {
    padding: 0 12px;
    width: 50%;
}

.counter-item-wrap-four .list-wrap li .counter-item-three {
    background: var(--tg-white);
    margin-bottom: 0;
}

.inner-counter-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}


/*=============================
	16. Estimate
===============================*/
.estimate-area {
    background: #F8F8FA;
    padding: 120px 0 0;
    position: relative;
    z-index: 1;
}

.estimate-content {
    padding: 0 40px 0 80px;
}

.estimate-tab-wrap .nav-tabs {
    border-bottom: none;
    gap: 5px;
    margin-bottom: 20px;
}

.estimate-tab-wrap .nav-tabs .nav-link {
    margin-bottom: 0;
    background: var(--tg-gray-four);
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    color: var(--tg-white);
    padding: 7px 20px;
}

.estimate-tab-wrap .nav-tabs .nav-link.active {
    background: var(--tg-primary-color);
}

.estimate-form-wrap .form-grp {
    margin-bottom: 10px;
}

.estimate-form-wrap .form-grp input {
    border: 1px solid #D0D6E1;
    border-radius: 5px;
    width: 100%;
    padding: 15px 20px;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-secondary-color);
    height: 50px;
    background: var(--tg-white);
}

.estimate-form-wrap .form-grp input::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-gray-four);
}

.range-slider-wrap {
    margin-bottom: 30px;
}

.range-slider-wrap .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.range-slider-wrap .content-top p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
}

.range-slider-wrap .content-top span {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: var(--tg-secondary-color);
}

.range-slider-wrap .content-top span strong {
    font-weight: 600;
    font-size: 16px;
}

.range-slider-wrap input {
    width: 100%;
    height: 15px;
    appearance: none;
    -webkit-appearance: none;
    background: #D9D9D9;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
}

.range-slider-wrap .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--tg-primary-color);
    cursor: pointer;
    border: none;
    box-shadow: -807px 0 0 800px #6699FF;
}

.estimate-form-wrap .btn.btn-three::after {
    display: none;
}

.estimate-shape {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}


/*=============================
	17. Project
===============================*/
.project-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
    overflow: hidden;
}

.project-top-content {
    margin-left: 40px;
    margin-bottom: 50px;
}

.project-top-content p {
    margin-bottom: 0;
    color: var(--tg-paragraph-color);
}

.project-item {
    margin-bottom: 30px;
}

.project-thumb {
    border-radius: 10px;
    overflow: hidden;
}

.project-thumb img {
    filter: grayscale(0);
}

.project-item:hover .project-thumb img {
    filter: grayscale(100%);
}

.project-content {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    margin: -85px 18px 0;
    padding: 22px 80px 22px 30px;
    position: relative;
    overflow: hidden;
    background: var(--tg-white);
}

.project-content .tag {
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.project-content .tag:hover {
    color: var(--tg-secondary-color);
}

.project-content .title {
    font-size: 22px;
    margin-bottom: 0;
}

.project-content .link-arrow {
    width: 113px;
    height: 113px;
    display: block;
    border-radius: 50%;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    font-size: 22px;
    line-height: 0;
    padding: 24px 32px;
    position: absolute;
    right: -38px;
    bottom: -51px;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
}

.project-item:hover .project-content .link-arrow {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.project-content .link-arrow:hover {
    background: var(--tg-secondary-color);
}

.swiper-container.project-active {
    overflow: hidden;
    margin-right: -422px;
}

/* project-two */
.project-bg-two {
    background-size: cover;
    background-position: center;
    padding: 120px 0 200px;
}

.project-content-top {
    margin-bottom: 30px;
}

.project-content-top p {
    margin-bottom: 0;
    width: 90%;
    margin-left: auto;
}

.project-item-two {
    position: relative;
    margin-bottom: 30px;
}

.project-thumb-two img {
    border-radius: 10px;
    transition: .3s linear;
}

.project-item-two:hover .project-thumb-two img {
    filter: grayscale(100%);
}

.project-content-two {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0 85 255 / 80%);
    border-radius: 10px;
    padding: 30px;
    transition: .3s linear;
    opacity: 0;
    transform: scale(.8);
    visibility: hidden;
}

.project-item-two:hover .project-content-two {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.project-content-two .title {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 10px;
    color: var(--tg-white);
}

.project-content-two .title a:hover {
    color: var(--tg-white);
}

.project-content-two span {
    display: block;
    line-height: 1;
    color: var(--tg-white);
}

.project-content-two .link-btn {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    font-size: 18px;
    position: absolute;
    right: 28px;
    bottom: 28px;
}

.project-content-two .link-btn:hover {
    background: var(--tg-secondary-color);
    color: var(--tg-white);
}

/* project-three */
.project-item-three {
    margin-bottom: 30px;
}

.project-thumb-three {
    overflow: hidden;
}

.project-thumb-three img {
    transform: scale(1);
    transition: .3s linear;
}

.project-item-three:hover .project-thumb-three img {
    transform: scale(1.1);
}

/* project-four */
.project-area-four {
    background: var(--tg-section-background);
    padding: 120px 0 190px;
}

.project-item-four {
    margin-bottom: 30px;
}

.project-thumb-four {
    position: relative;
    margin-bottom: 20px;
}

.project-thumb-four::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--tg-primary-color);
    opacity: .9;
    opacity: 0;
    transition: .3s linear;
}

.project-thumb-four .project-link {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    width: 50px;
    opacity: 0;
    visibility: hidden;
    line-height: 0;
}

.project-item-four:hover .project-thumb-four::before {
    opacity: .9;
}

.project-item-four:hover .project-thumb-four .project-link {
    opacity: 1;
    visibility: visible;
}

.project-content-four .title {
    font-size: 24px;
    margin-bottom: 5px;
}

.project-content-four span {
    font-weight: 400;
    display: block;
    line-height: 1;
    color: var(--tg-gray-four);
}

/* project-details */
.project-details-wrap .row .col-71 {
    width: 71%;
    flex: 0 0 auto;
}

.project-details-wrap .row .col-29 {
    width: 29%;
    flex: 0 0 auto;
}

.project-details-thumb {
    margin-bottom: 30px;
}

.project-details-thumb img {
    border-radius: 10px;
}

.project-details-info {
    border: 1px solid #E0E6F2;
    border-radius: 5px;
    padding: 25px 35px 28px;
    margin-bottom: 30px;
}

.project-details-info .title {
    font-size: 24px;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 12px;
}

.project-details-info .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    background: var(--tg-primary-color);
    border-radius: 4px;
}

.project-details-info>.list-wrap>li {
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-gray-four);
    border-bottom: 1px solid #DFE3E7;
    display: flex;
    align-items: center;
    font-family: var(--tg-heading-font-family);
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.project-details-info>.list-wrap>li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.project-details-info>.list-wrap>li span {
    font-weight: 700;
    font-size: 18px;
    color: var(--tg-paragraph-color);
    width: 40%;
    flex: 0 0 auto;
}

.project-details-info>.list-wrap>li.social .list-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.project-details-info>.list-wrap>li.social .list-wrap li a {
    color: var(--tg-gray-four);
}

.project-details-info>.list-wrap>li.social .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.project-details-content .title {
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 20px;
}

.project-details-content>p {
    margin-bottom: 15px;
}

.project-details-content .title-two {
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: capitalize;
}

.pd-optimized-wrap {
    margin-top: 50px;
    margin-bottom: 60px;
}

.pd-optimized-wrap p {
    margin-bottom: 0;
}

.pd-inner-wrap .row .col-41 {
    width: 41.3%;
    flex: 0 0 auto;
}

.pd-inner-wrap .row .col-59 {
    width: 58.7%;
    flex: 0 0 auto;
}

.pd-inner-wrap .content {
    margin-right: 20px;
}

.pd-inner-wrap .content p {
    margin-bottom: 0;
}

.pd-inner-wrap .content .info-one {
    margin-bottom: 30px;
}

.pd-inner-wrap .content .list-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 18px 0;
    margin-bottom: 25px;
}

.pd-inner-wrap .content .list-wrap li {
    display: flex;
    align-items: flex-start;
    line-height: 1.3;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-paragraph-color);
    width: 50%;
}

.pd-inner-wrap .content .list-wrap li img {
    margin-right: 10px;
}

.pd-inner-wrap .thumb {
    position: relative;
}

.pd-inner-wrap .thumb img {
    border-radius: 10px;
}

.pd-inner-wrap .thumb .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
}

.pd-inner-wrap .thumb .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    animation: pulse-border 1500ms ease-out infinite;
}

/*=============================
	18. Faq
===============================*/
.faq-area {
    position: relative;
    z-index: 1;
    padding: 120px 0;
}

.faq-bg-shape {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40.3%;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.faq-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.faq-shape-wrap img:nth-child(1) {
    right: 0;
    bottom: 0;
}

.faq-shape-wrap img:nth-child(2) {
    left: 14%;
    bottom: 12%;
    animation: alltuchtopdown 8s infinite linear;
}

.faq-img-wrap {
    text-align: right;
    position: relative;
    margin-right: 45px;
}

.faq-img-wrap img:nth-child(1) {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 8px solid var(--tg-white);
}

.faq-img-wrap img:nth-child(2) {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 20%;
}

.faq-content>p {
    margin-bottom: 30px;
    width: 85%;
}

.faq-content .accordion-wrap {
    width: 90%;
}

.faq-content .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.faq-content .accordion-item {
    background-color: transparent;
    border: none;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 10px;
}

.faq-content .accordion-item:last-child {
    margin-bottom: 0;
}

.faq-content .accordion-button {
    position: relative;
    padding: 17px 50px 17px 30px;
    font-weight: 700;
    font-size: 18px;
    color: var(--tg-secondary-color);
    text-align: left;
    background-color: var(--tg-gray-two);
    font-family: var(--tg-heading-font-family);
    border: 1px solid #DFDFDF;
}

.faq-content .accordion-button.collapsed {
    border: transparent;
}

.faq-content .accordion-button:not(.collapsed) {
    color: var(--tg-primary-color);
    background-color: transparent;
    box-shadow: none;
}

.faq-content .accordion-button:focus {
    border-color: #DFDFDF;
    box-shadow: none;
}

.faq-content .accordion-body {
    padding: 20px 30px 30px;
    border: 1px solid #DFDFDF;
    border-top: none;
}

.faq-content .accordion-body p {
    margin-bottom: 0;
    color: var(--tg-gray-four);
}

.faq-content .accordion-button::after {
    content: "\f102";
    width: auto;
    height: auto;
    background-image: none;
    font-family: "flaticon";
    font-size: 16px;
    position: absolute;
    right: 25px;
    top: 18px;
    color: var(--tg-primary-color);
}

.faq-content .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: rotate(-180deg);
}

/*=============================
	19. Request
===============================*/
.request-area.request-bg {
    background-position: center;
    background-size: cover;
    padding: 80px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.request-content .title {
    font-size: 32px;
    margin-bottom: 0;
    color: var(--tg-white);
    font-weight: 800;
}

.request-content-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.request-contact {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 5px 22px;
}

.request-contact .icon {
    font-size: 30px;
    line-height: 0;
    color: var(--tg-white);
    margin-right: 10px;
}

.request-contact .content span {
    display: block;
    line-height: 1;
    font-size: 13px;
    color: var(--tg-white);
    margin-bottom: 2px;
}

.request-contact .content a {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    line-height: 1;
}

.request-contact .content a:hover {
    color: var(--tg-primary-color);
}

.request-shape {
    position: absolute;
    left: 6%;
    top: 10%;
    animation: animationFramesOne 15s linear infinite;
    z-index: -1;
}

/* request-two */
.request-area-two {
    background: var(--tg-secondary-color);
    padding: 120px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.request-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.request-shape-wrap img:nth-child(1) {
    left: 13%;
    top: 45%;
    animation: alltuchtopdown 8s infinite linear;
}

.request-shape-wrap img:nth-child(2) {
    right: 0;
    top: 0;
}

.request-content-two p {
    margin-bottom: 0;
    color: #D0D0DD;
    width: 90%;
}

.request-form-wrap .form-grp input {
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 13px;
    color: var(--tg-secondary-color);
    padding: 15px 20px;
    border-radius: 4px;
    background: var(--tg-white);
    height: 50px;
}

.request-form-wrap .form-grp input::placeholder {
    font-weight: 500;
    font-size: 13px;
    color: var(--tg-paragraph-color);
}

.request-form-wrap .row {
    gap: 15px 0;
    margin: 0 -7px;
}

.request-form-wrap .row [class*="col-"] {
    padding: 0 7px;
}

.request-form-wrap button {
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 4px;
    height: 50px;
}

.request-form-wrap button:hover {
    background: var(--tg-white);
    color: var(--tg-primary-color);
}

/*=============================
	20. Team
===============================*/
.team-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
}

.team-item {
    border: 1px solid #DFE3EC;
    border-radius: 10px;
    background: var(--tg-white);
    margin-bottom: 30px;
    transition: .3s linear;
}

.team-item:hover {
    filter: drop-shadow(0px 14px 30px #DDE1EB);
    border-color: var(--tg-white);
}

.team-thumb {
    position: relative;
}

.team-thumb img {
    border-radius: 8px 8px 180px;
}

.team-social .list-wrap {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    border-radius: 0px 0px 50px 50px;
    background: var(--tg-paragraph-color);
    right: 17px;
    top: 0;
    padding: 16px 13px;
    transition: .3s linear;
}

.team-social .list-wrap li a {
    display: block;
    transform: rotate(-90deg);
    color: var(--tg-white);
    font-size: 16px;
}

.team-item:hover .team-social .list-wrap {
    background: var(--tg-primary-color);
}

.team-content {
    padding: 20px 25px 25px;
}

.team-content .title {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 3px;
}

.team-content span {
    font-size: 13px;
    display: block;
    position: relative;
    padding-bottom: 15px;
    color: var(--tg-gray-four);
}

.team-content span::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

/* team-two */
.team-area-two {
    padding: 120px 0 90px;
    position: relative;
    overflow: hidden;
}

.team-shape {
    position: absolute;
    left: 0;
    top: 30%;
    z-index: -1;
}

.section-top-content p {
    margin-bottom: 0;
    width: 90%;
    margin-left: auto;
    color: var(--tg-paragraph-color);
}

.team-item-two {
    position: relative;
    margin-bottom: 30px;
    transition: .3s;
}

.team-item-two:hover {
    transform: translateY(-5px);
}

.team-thumb-two {
    position: relative;
}

.team-thumb-two img {
    border-radius: 10px;
}

.team-social-two .list-wrap {
    display: flex;
    flex-direction: column-reverse;
    background: #0045CE;
    position: absolute;
    left: 30px;
    bottom: 25px;
    border-radius: 50px;
    padding: 16px 13px;
}

.team-social-two .list-wrap li a {
    display: block;
    transform: rotate(-90deg);
    color: var(--tg-white);
    font-size: 16px;
    transition: .3s;
}

.team-social-two .list-wrap li a:hover {
    transform: rotate(0);
}

.team-content-two {
    position: absolute;
    left: 30px;
    top: 25px;
    right: 30px;
}

.team-content-two .title {
    font-size: 24px;
    margin-bottom: 5px;
    color: var(--tg-white);
}

.team-content-two .title a:hover {
    color: var(--tg-secondary-color);
}

.team-content-two span {
    font-weight: 500;
    font-size: 14px;
    display: block;
    color: var(--tg-lavender);
    position: relative;
}

.team-content-two span::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 30px;
    height: 4px;
    background: var(--tg-white);
    border-radius: 2px;
}

/* team-three */
.team-area-three {
    position: relative;
    padding: 120px 0 90px;
    overflow: hidden;
}

.team-area-three::before {
    content: "";
    position: absolute;
    left: 50px;
    bottom: 0;
    width: 600px;
    height: 600px;
    background: #CED0FF;
    opacity: 0.2;
    filter: blur(75px);
    z-index: -1;
}

.team-item-three {
    background: var(--tg-section-background);
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
}

.team-thumb-three {
    position: relative;
    z-index: 1;
    padding-top: 25px;
}

.team-thumb-three::before {
    content: "";
    position: absolute;
    width: 230px;
    height: 450px;
    background: var(--tg-lavender);
    right: -68px;
    bottom: -31px;
    z-index: -1;
    border-radius: 115px;
    transform: rotate(45deg);
    transition: .3s ease-in-out;
}

.team-item-three:hover .team-thumb-three::before {
    background: var(--tg-primary-color);
}

.team-content-three {
    padding: 20px 30px 25px;
}

.team-content-three .title {
    margin-bottom: 8px;
    font-size: 24px;
}

.team-content-three span {
    display: block;
    color: var(--tg-primary-color);
}

.team-social-three {
    position: absolute;
    left: 20px;
    top: 20px;
    background: var(--tg-lavender);
    border-radius: 30px;
}

.team-social-three .list-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 0 0 15px;
    display: none;
    text-align: center;
}

.team-social-three .social-toggle-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--tg-primary-color);
    font-size: 20px;
    line-height: 0;
    cursor: pointer;
    transition: all 0.3s ease-out 0s;
}

.team-social-three .list-wrap a {
    font-size: 18px;
}

.team-social-three .list-wrap a:hover {
    color: var(--tg-secondary-color);
}

/* team-four */
.team-item-four {
    margin-bottom: 30px;
}

.team-thumb-four {
    position: relative;
    margin-bottom: 20px;
}

.team-thumb-four img {
    border-radius: 10px;
}

.team-content-four .title {
    font-size: 24px;
    margin-bottom: 5px;
}

.team-content-four span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-primary-color);
}

.team-item-four .team-social-three {
    right: 20px;
    bottom: 20px;
    top: auto;
    left: auto;
    display: flex;
    flex-direction: column-reverse;
}

.team-item-four .team-social-three .list-wrap {
    padding: 15px 0 0;
}


/* team-five */
.team-item-five {
    border: 1px solid #D4DCED;
    border-radius: 30px;
    text-align: center;
    margin-bottom: 30px;
    padding: 40px 40px 50px;
}

.team-thumb-five {
    margin-bottom: 20px;
}

.team-thumb-five img {
    border-radius: 50%;
}

.team-content-five .title {
    font-size: 22px;
    margin-bottom: 5px;
}

.team-content-five span {
    display: block;
    color: var(--tg-gray-four);
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.team-content-five span::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background: var(--tg-primary-color);
}

.team-social-four .list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.team-social-four .list-wrap li a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--tg-lavender);
    font-size: 15px;
}

.team-social-four .list-wrap li a:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}

/* team-six */
.team-area-six {
    background: var(--tg-section-background);
    padding: 220px 0 90px;
    margin-top: -100px;
}

/* team-details */
.team-details-info-wrap {
    margin-right: 30px;
}

.team-details-thumb {
    margin-bottom: 30px;
}

.team-details-thumb img {
    border-radius: 10px;
}

.team-details-info .list-wrap {
    margin-bottom: 30px;
}

.team-details-info .list-wrap li {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    margin-bottom: 10px;
}

.team-details-info .list-wrap li:last-child {
    margin-bottom: 0;
}

.team-details-info .list-wrap li i {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 0;
    color: var(--tg-primary-color);
    font-size: 20px;
    background: var(--tg-lavender);
    transform: translateY(-4px);
    margin-right: 10px;
}

.td-info-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.td-info-bottom .share-btn {
    background: var(--tg-lavender);
    border-radius: 30px;
    text-transform: uppercase;
    padding: 13px 24px;
    display: block;
    height: 52px;
}

.td-info-bottom .share-btn img {
    margin-right: 10px;
    transition: all 0.3s ease-out 0s;
}

.td-info-bottom .share-btn:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.td-info-bottom .share-btn:hover img {
    filter: brightness(0) invert(1);
}

.team-details-content>.title {
    margin-bottom: 8px;
    font-size: 36px;
    text-transform: capitalize;
}

.team-details-content>span {
    display: block;
    line-height: 1;
    color: var(--tg-gray-four);
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 25px;
}

.team-details-content>span::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.team-details-content p {
    margin-bottom: 0;
}

.team-skill-wrap {
    margin-top: 45px;
}

.team-skill-wrap>.title-two {
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 20px;
}

.team-skill-wrap>.title-two::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.team-skill-wrap p {
    margin-bottom: 0;
}

.team-skill-wrap .progress-wrap {
    margin: 25px 0 40px;
}



/*=============================
	21. Testimonial
===============================*/
.testimonial-bg {
    background-size: cover;
    background-position: left center;
    padding: 120px 0;
}

.testimonial-img {
    position: relative;
    text-align: center;
}

.testimonial-img>img {
    border-radius: 30px 85px 30px 95px;
    border: 6px solid var(--tg-white);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.25);
}

.testimonial-img .review-wrap {
    background: var(--tg-white);
    max-width: 180px;
    border-radius: 20px;
    padding: 20px 25px 20px;
    position: absolute;
    left: -30px;
    bottom: 17%;
}

.testimonial-img .review-wrap img {
    margin-bottom: 15px;
}

.testimonial-img .review-wrap .content {
    display: flex;
    align-items: center;
}

.testimonial-img .review-wrap .content .title {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 800;
    line-height: 1;
    color: var(--tg-primary-color);
    margin-right: 10px;
}

.testimonial-img .review-wrap .content p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    color: var(--tg-secondary-color);
    line-height: 1.2;
}

.testimonial-content {
    width: 82%;
}

.testimonial-content .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.testimonial-content .content-top .rating {
    display: flex;
    align-items: center;
    color: var(--tg-yellow);
    font-size: 20px;
    gap: 5px;
}

.testimonial-content p {
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}

.testimonial-info .title {
    color: var(--tg-white);
    font-size: 22px;
    margin-bottom: 8px;
}

.testimonial-info span {
    display: block;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-lavender);
    position: relative;
    padding-bottom: 16px;
    line-height: 1;
}

.testimonial-info span::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.testimonial-nav {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 40px;
}

.testimonial-nav button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #0F2D6A;
    color: var(--tg-white);
    font-size: 22px;
    line-height: 0;
    border-radius: 50%;
}

.testimonial-nav button.slick-prev {
    transform: rotate(-180deg);
}

.testimonial-nav button:hover {
    background: var(--tg-primary-color);
}

/* testimonial-two */
.testimonial-bg-two {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
}

.section-title-two.white-title .sub-title {
    background: #1E376A;
    color: var(--tg-white);
}

.testimonial-item-wrap-two {
    padding: 0 30px;
    position: relative;
}

.testimonial-item-two {
    background: var(--tg-white);
    border-radius: 10px;
    padding: 40px 40px;
}

.testimonial-content-two .rating {
    display: flex;
    align-items: center;
    color: var(--tg-yellow);
    font-size: 20px;
    gap: 5px;
    margin-bottom: 20px;
}

.testimonial-content-two>p {
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    color: var(--tg-paragraph-color);
    font-family: var(--tg-heading-font-family);
    line-height: 1.5;
}

.testimonial-avatar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.testimonial-avatar .avatar-thumb {
    width: 65px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.testimonial-avatar .avatar-thumb img {
    border-radius: 50%;
}

.testimonial-avatar .avatar-info {
    position: relative;
}

.testimonial-avatar .avatar-info::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 36px;
    border-radius: 2px;
    background: var(--tg-primary-color);
}

.testimonial-avatar .avatar-info .title {
    font-size: 22px;
    margin-bottom: 5px;
    color: var(--tg-paragraph-color);
}

.testimonial-avatar .avatar-info span {
    font-family: var(--tg-heading-font-family);
    line-height: 1;
    display: block;
    font-weight: 400;
}

.testimonial-active-two [class*="col-"] {
    padding: 0 15px;
}

.testimonial-nav-two .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50px;
    line-height: 0;
    color: var(--tg-white);
    font-size: 22px;
    background: #0F2D6A;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
}

.testimonial-nav-two .slick-arrow.slick-next {
    left: auto;
    right: -40px;
}

.testimonial-nav-two .slick-arrow.slick-prev i {
    transform: rotate(-180deg);
}

.testimonial-nav-two .slick-arrow:hover {
    color: #0F2D6A;
    background: var(--tg-white);
}

/* testimonial-three */
.testimonial-area-three .row .col-37 {
    width: 37%;
    flex: 0 0 auto;
}

.testimonial-area-three .row .col-63 {
    width: 63%;
    flex: 0 0 auto;
}

.testimonial-img-three img {
    clip-path: polygon(0 0, 85% 0, 100% 15%, 100% 100%, 15% 100%, 0 85%);
    width: 100%;
}

.testimonial-item-wrap-three {
    background: var(--tg-section-background);
    border-radius: 10px;
    padding: 100px 80px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.testimonial-content-three .rating {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 5px;
    color: var(--tg-yellow);
    margin-bottom: 30px;
}

.testimonial-content-three p {
    font-weight: 600;
    font-size: 20px;
    color: var(--tg-secondary-color);
    font-style: italic;
    font-family: var(--tg-heading-font-family);
    line-height: 1.5;
    margin-bottom: 35px;
}

.testimonial-content-three .testimonial-info .title {
    color: var(--tg-secondary-color);
}

.testimonial-content-three .testimonial-info span {
    color: var(--tg-paragraph-color);
}

.testimonial-nav-three {
    position: absolute;
    display: flex;
    align-items: center;
    left: 45%;
    bottom: 25%;
    gap: 10px;
}

.testimonial-nav-three .slick-arrow {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tg-white);
    background: var(--tg-secondary-color);
    line-height: 0;
    font-size: 22px;
}

.testimonial-nav-three .slick-arrow:hover {
    background: var(--tg-primary-color);
}

.testimonial-nav-three .slick-arrow.slick-prev {
    transform: rotate(-180deg);
}

/* testimonial-four */
.testimonial-bg-four {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.testimonial-img-four {
    position: relative;
    z-index: 1;
    padding-left: 42px;
}

.testimonial-img-four::before {
    content: "";
    position: absolute;
    width: 335px;
    height: 335px;
    left: 10.2%;
    bottom: 1px;
    background: #FFF9E4;
    border-radius: 50%;
    z-index: -1;
}

.testimonial-img-four .icon {
    position: absolute;
    left: 7%;
    top: 18%;
    width: 80px;
    height: 80px;
    background: var(--tg-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 59% 41% 28% 72% / 38% 37% 63% 62%;
}

.testimonial-img-four .shape {
    position: absolute;
    left: 0;
    bottom: -20px;
    z-index: -2;
}

.testimonial-item-wrap-four {
    position: relative;
    padding-right: 125px;
}

.testimonial-content-four .rating {
    display: flex;
    align-items: center;
    color: var(--tg-yellow);
    gap: 5px;
    margin-bottom: 35px;
}

.testimonial-content-four>p {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
    font-style: italic;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
}

.testimonial-content-four .testimonial-info span::before {
    display: none;
}

.testimonial-content-four .testimonial-info span {
    padding-bottom: 0;
}

.testimonial-content-four .testimonial-info {
    position: relative;
    padding-left: 16px;
}

.testimonial-content-four .testimonial-info::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 40px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.testimonial-nav-four {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    gap: 15px;
}

.testimonial-nav-four .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0F2D6A;
    border: none;
    border-radius: 50%;
    color: var(--tg-white);
    font-size: 22px;
    line-height: 0;
}

.testimonial-nav-four .slick-arrow:hover {
    background: var(--tg-primary-color);
}

.testimonial-nav-four .slick-arrow.slick-next {
    transform: rotate(180deg);
}

.testimonial-shape-wrap-four img {
    position: absolute;
    z-index: -1;
}

.testimonial-shape-wrap-four img:nth-child(1) {
    left: 0;
    bottom: 0;
}

.testimonial-shape-wrap-four img:nth-child(2) {
    right: 0;
    top: 0;
}


/* testimonial-five */
.testimonial-area-five {
    position: relative;
    padding: 120px 0;
    overflow: hidden;
}

.testimonial-area-five::before {
    content: "";
    position: absolute;
    right: 22%;
    bottom: 22%;
    width: 350px;
    height: 350px;
    background: #FAE7FF;
    filter: blur(200px);
    z-index: -1;
    border-radius: 50%;
}

.testimonial-img-five {
    position: relative;
    text-align: center;
}

.testimonial-img-five img:not(:nth-child(1)) {
    position: absolute;
    z-index: -1;
}

.testimonial-img-five .shape-one {
    left: 3%;
    bottom: 7px;
}

.testimonial-img-five .shape-two {
    left: 17%;
    top: 35%;
    animation: alltuchtopdown 8s infinite linear;
}

.testimonial-img-five .shape-three {
    left: 11%;
    bottom: 12%;
    animation: rotateme 8s infinite linear;
}

.testimonial-content-five {
    padding-right: 112px;
}

.testimonial-item-wrap-five {
    position: relative;
}

.testimonial-item-wrap-five .testimonial-content p {
    color: var(--tg-paragraph-color);
}

.testimonial-item-wrap-five .testimonial-content {
    width: 100%;
}

.testimonial-item-wrap-five .testimonial-quote img {
    opacity: .4;
}

.testimonial-active-five {
    margin: -15px 0;
}

.testimonial-active-five .testimonial-item {
    padding: 15px 0;
}

.testimonial-nav-five {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: -120px;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
    gap: 15px;
}

.testimonial-nav-five .slick-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C2C2C2;
    transform: rotate(-90deg);
    color: var(--tg-primary-color);
    font-size: 20px;
    line-height: 0;
    background: transparent;
    border-radius: 50%;
}

.testimonial-nav-five .slick-arrow.slick-next {
    transform: rotate(90deg);
}

.testimonial-nav-five .slick-arrow:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
}


/*=============================
	22. Pricing
===============================*/
.pricing-area {
    position: relative;
    overflow: hidden;
    padding: 120px 0 90px;
}

.pricing-area::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -35%;
    width: 760px;
    height: 760px;
    background: linear-gradient(154.68deg, #8894FF 11.62%, #FF8585 90.47%);
    opacity: 0.1;
    filter: blur(75px);
    border-radius: 50%;
    z-index: -1;
}

.pricing-item-wrap .pricing-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 40px;
}

.pricing-item-wrap .pricing-tab .tab-btn {
    cursor: pointer;
    color: var(--tg-paragraph-color);
    font-weight: 600;
    font-size: 18px;
    user-select: none;
    font-family: var(--tg-heading-font-family);
    transition: .3s;
}

.pricing-item-wrap .pricing-tab .tab-btn.monthly_tab_title {
    color: var(--tg-primary-color);
}

.pricing-item-wrap .pricing-tab .tab-btn.monthly_tab_title.active {
    color: var(--tg-paragraph-color);
}

.pricing-item-wrap .pricing-tab .annual_tab_title.active {
    color: var(--tg-primary-color);
}

.pricing-tab .pricing-tab-switcher {
    height: 30px;
    width: 70px;
    display: inline-block;
    border-radius: 30px;
    position: relative;
    margin: 0 15px;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    cursor: pointer;
    background: var(--tg-primary-color);
}

.pricing-tab .pricing-tab-switcher:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    width: 26px;
    border-radius: 50%;
    background: var(--tg-white);
    transition: .3s;
}

.pricing-tab .pricing-tab-switcher.active:before {
    left: calc(100% - 28px);
}

.pricing-price.change-subs-duration .monthly_price {
    display: none;
}

.pricing-price.change-subs-duration .annual_price {
    display: flex;
}

.pricing-box {
    border: 1px solid #E1E1E9;
    border-radius: 10px;
    text-align: center;
    padding: 40px 40px;
    background: var(--tg-white);
    margin-bottom: 30px;
    position: relative;
    margin-top: 16px;
}

.pricing-box .popular-tag {
    display: inline-block;
    font-weight: 600;
    font-size: 15px;
    background: var(--tg-red);
    color: var(--tg-white);
    line-height: 1.2;
    padding: 7px 18px;
    border-radius: 30px;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
}

.pricing-head .title {
    font-size: 24px;
    margin-bottom: 10px;
}

.pricing-head p {
    margin-bottom: 24px;
    color: var(--tg-gray-four);
}

.pricing-price {
    margin-bottom: 25px;
}

.pricing-price .price {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: #F3F6FE;
    border-radius: 30px;
    padding: 15px 20px;
}

.pricing-price .price strong {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--tg-body-font-family);
    margin: 4px 3px auto 0;
}

.pricing-price .price span {
    color: #798EB9;
    text-transform: lowercase;
    font-weight: 500;
    font-size: 13px;
    font-family: var(--tg-body-font-family);
    margin-left: 5px;
    margin-bottom: 7px;
}

.pricing-price .annual_price {
    display: none;
}

.pricing-list .list-wrap {
    margin-bottom: 25px;
}

.pricing-list .list-wrap li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tg-paragraph-color);
    margin-bottom: 15px;
}

.pricing-list .list-wrap li:last-child {
    margin-bottom: 0;
}

.pricing-list .list-wrap li img {
    margin-right: 10px;
}

.pricing-btn .btn {
    background: var(--tg-lavender);
    color: var(--tg-primary-color);
}

.pricing-btn .btn::before {
    background: var(--tg-cornflower-blue);
}

.pricing-box.active .pricing-btn .btn {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.pricing-box.active .pricing-btn .btn::before {
    background: var(--tg-blue);
}

/* pricing-two */
.pricing-area-two {
    position: relative;
    padding: 120px 0 90px;
    overflow: hidden;
}

.pricing-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

.pricing-box-two {
    border: 1px solid #E1E1E9;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
}

.pricing-box-two .popular {
    font-weight: 600;
    font-size: 15px;
    background: var(--tg-red);
    color: var(--tg-white);
    display: block;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 80px;
    border-radius: 30px 0 0 30px;
    padding: 8px 18px;
    z-index: -1;
}

.pricing-head-two {
    padding: 50px 50px 40px;
    border-bottom: 1px solid #E1E1E9;
}

.pricing-head-two .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.pricing-price-two .price {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
    line-height: 1;
}

.pricing-price-two .price strong {
    font-weight: 600;
    font-size: 20px;
    margin: 4px 3px auto 0;
}

.pricing-price-two .price span {
    color: #798EB9;
    text-transform: lowercase;
    font-weight: 500;
    font-size: 13px;
    font-family: var(--tg-body-font-family);
    margin-left: 2px;
    margin-bottom: 7px;
}

.pricing-price-two .annual_price {
    display: none;
}

.pricing-price-two.change-subs-duration .annual_price {
    display: flex;
}

.pricing-price-two.change-subs-duration .monthly_price {
    display: none;
}

.pricing-bottom {
    padding: 30px 50px 50px;
}

.pricing-box-two .pricing-list .list-wrap {
    margin-bottom: 30px;
}

.pricing-box-two .pricing-list .list-wrap li {
    justify-content: left;
}

.pricing-btn-two {
    text-align: center;
}

/* pricing-three */
.pricing-area-three {
    position: relative;
    padding: 120px 0 90px;
    overflow: hidden;
    background: #F8F8FA;
    z-index: 1;
}

.pricing-box-three {
    border: 1px solid #E1E1E9;
    border-radius: 20px;
    padding: 50px 50px 60px;
    text-align: center;
    background: var(--tg-white);
    margin-bottom: 30px;
}

.pricing-box-three .pricing-icon {
    font-size: 65px;
    line-height: 0;
    color: var(--tg-primary-color);
    margin-bottom: 20px;
}

.pricing-box-three .pricing-plan .title {
    color: var(--tg-gray-four);
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
}

.pricing-box-three .pricing-price-two {
    margin-bottom: 30px;
}

.pricing-box-three .pricing-price-two .price {
    justify-content: center;
}

.pricing-box-three .pricing-list .list-wrap {
    margin-bottom: 35px;
}


/*=============================
	23. Contact
===============================*/
.contact-bg {
    background-size: cover;
    background-position: center;
    padding: 120px 0;
    position: relative;
    z-index: 1;
}

.contact-content p {
    margin-bottom: 0;
}

.contact-form {
    margin: 0 30px 0 80px;
}

.contact-form .form-grp {
    margin-bottom: 15px;
}

.contact-form .form-grp textarea,
.contact-form .form-grp input,
.contact-form .form-grp select {
    width: 100%;
    border: 1px solid #D5DAE4;
    border-radius: 4px;
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    font-weight: 500;
    font-size: 13px;
    padding: 14px 18px;
    height: 50px;
    display: block;
}

#__next>main>section.contact-area.contact-bg>div.container>div>div.col-lg-7>div>form>div>div:nth-child(5)>div>div>input,
#__next>main>div>section>div.container>div>div.col-lg-8>div>form>div>div:nth-child(4)>div>div>input,
#__next>main>div>section.contact-area.contact-bg.position-relative>div.container>div>div.col-lg-7>div>form>div>div:nth-child(5)>div>div>input {
    padding-left: 48px;
}

.react-tel-input .country-list .search-box {
    display: inline;
    height: auto;
    width: auto;
}

.disable_ms {
    opacity: 0.8 !important;
}

.react-international-phone-input-container input {
    height: 50px !important;
}

.react-international-phone-country-selector-dropdown {
    z-index: 9999 !important;
}

.contact-form .form-grp textarea::placeholder,
.contact-form .form-grp input::placeholder {
    color: var(--tg-paragraph-color);
    font-weight: 500;
    font-size: 13px;
}

.contact-form .form-grp textarea {
    min-height: 96px;
    max-height: 96px;
}

.contact-form form button[type="submit"] {
    border: none;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: var(--tg-white);
    text-transform: uppercase;
    background: var(--tg-primary-color);
    border-radius: 30px;
    padding: 14px 20px;
}

.contact-form form button:hover {
    background: var(--tg-secondary-color);
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    height: 50px;
    padding: 15px;
}

.contact-shape {
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 31%;
    animation: animationFramesOne 15s linear infinite;
}

/* inner-contact */
.inner-contact-info {
    padding-left: 80px;
}

.inner-contact-info .title {
    margin-bottom: 25px;
    position: relative;
    font-weight: 800;
    font-size: 36px;
    padding-bottom: 20px;
}

.inner-contact-info .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    background: var(--tg-primary-color);
    border-radius: 4px;
}

.contact-info-item {
    margin-bottom: 50px;
}

.contact-info-item:last-child {
    margin-bottom: 0;
}

.contact-info-item .title-two {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-info-item .list-wrap li {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.33;
    font-family: var(--tg-heading-font-family);
}

.contact-info-item .list-wrap li:last-child {
    margin-bottom: 0;
}

.contact-map {
    width: 100%;
    height: 500px;
}

.contact-map iframe {
    width: 100%;
    height: 100%;
}


/*=============================
	24. Blog
===============================*/
.blog-post-area {
    position: relative;
    z-index: 1;
    padding: 120px 0 90px;
}

.blog-post-area .blog-bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 62%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.blog-post-item {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background: var(--tg-white);
    border-radius: 10px;
    margin-bottom: 30px;
}

.blog-post-thumb {
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.blog-post-thumb img {
    width: 100%;
    transform: scale(1);
}

.blog-post-item:hover .blog-post-thumb img {
    transform: scale(1.1);
}

.blog-post-thumb .date {
    background: var(--tg-primary-color);
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-white);
    line-height: 1;
    position: absolute;
    left: 30px;
    top: 0;
    padding: 15px 20px;
    border-radius: 0 0 30px 30px;
    text-align: center;
}

.blog-post-thumb .date strong {
    font-weight: 700;
    font-size: 24px;
    font-family: var(--tg-heading-font-family);
    display: block;
    line-height: 1;
}

.blog-post-content {
    padding: 25px 30px 30px;
    border-radius: 0 0 10px 10px;
}

.blog-post-content .tag {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 8px;
}

.blog-post-content .tag:hover {
    color: var(--tg-secondary-color);
}

.blog-post-content .title {
    font-size: 24px;
    margin-bottom: 10px;
}

.blog-post-content p {
    margin-bottom: 20px;
}

.blog-post-content .link-btn {
    letter-spacing: 0.02em;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    color: var(--tg-paragraph-color);
}

.blog-post-content .link-btn i {
    line-height: 0;
    font-size: 22px;
    margin-left: 10px;
    color: var(--tg-primary-color);
}

.blog-post-content .link-btn:hover {
    color: var(--tg-primary-color);
}

/* blog-two */
.blog-bg-two {
    background-size: cover;
    background-position: center;
    padding: 120px 0 90px;
}

.section-title-two p {
    color: var(--tg-paragraph-color);
    width: 90%;
    margin: 15px auto 0;
}

.blog-post-item-two {
    margin-bottom: 30px;
}

.blog-post-thumb-two {
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.blog-post-thumb-two img {
    transition: .3s linear;
    width: 100%;
    min-height: 275px;
}

.blog-post-item-two:hover .blog-post-thumb-two img {
    transform: scale(1.1);
}

.blog-post-thumb-two .tag {
    position: absolute;
    left: 0;
    bottom: 0;
    font-weight: 700;
    font-size: 14px;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    padding: 9px 16px;
    display: block;
}

.blog-post-thumb-two .tag:hover {
    background: var(--tg-secondary-color);
}

.blog-post-content-two {
    border: 1px solid #E7E7E7;
    border-top: none;
    border-radius: 0 0 10px 10px;
    padding: 35px 30px;
    background: var(--tg-white);
}

.blog-post-content-two .title {
    font-size: 24px;
    margin-bottom: 10px;
}

.blog-post-content-two p {
    margin-bottom: 25px;
}

.blog-meta .list-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px 10px;
}

.blog-meta .list-wrap li {
    color: var(--tg-paragraph-color);
    font-size: 13px;
    display: flex;
    align-items: center;
}

.blog-meta .list-wrap li i {
    font-size: 20px;
    color: var(--tg-primary-color);
    margin-right: 8px;
}

.blog-meta .list-wrap li a {
    color: var(--tg-paragraph-color);
    font-size: 16px;
}

.blog-meta .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.blog-meta .list-wrap li a img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    flex: 0 0 auto;
}


/* blog-three */
.blog-post-thumb-two .tag-two {
    position: absolute;
    left: 30px;
    top: 25px;
    bottom: auto;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    padding: 6px 16px;
    display: block;
    border-radius: 30px;
}

.blog-post-thumb-two .tag-two:hover {
    background: var(--tg-secondary-color);
}


/* blog-four */
.blog-post-thumb-four {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.blog-post-thumb-four img {
    width: 100%;
}

.blog-post-content-four {
    border: 1px solid #E7E7E7;
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 40px 35px 35px;
    position: relative;
    margin-bottom: 30px;
}

.blog-post-content-four .tag {
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-transform: capitalize;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    position: absolute;
    left: 30px;
    top: -18px;
    padding: 8px 16px;
    border-radius: 30px;
}

.blog-post-content-four .tag:hover {
    background: var(--tg-secondary-color);
}

.blog-meta-two .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 25px;
    margin-bottom: 20px;
}

.blog-meta-two .list-wrap li {
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.blog-meta-two .list-wrap li i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--tg-primary-color);
}

.blog-meta-two .list-wrap li a {
    color: var(--tg-paragraph-color);
    margin-left: 5px;
}

.blog-meta-two .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.blog-post-content-four .title {
    font-size: 24px;
    margin-bottom: 15px;
}

.blog-post-content-four .link-btn {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: var(--tg-paragraph-color);
}

.blog-post-content-four .link-btn i {
    line-height: 0;
    font-size: 20px;
    color: var(--tg-primary-color);
    margin-left: 10px;
}

.blog-post-content-four .link-btn:hover {
    color: var(--tg-primary-color);
}

/* inner-blog */
.inner-blog-wrap .row .col-71 {
    width: 71%;
    flex: 0 0 auto;
}

.inner-blog-wrap .row .col-29 {
    width: 29%;
    flex: 0 0 auto;
}

.pagination-wrap .pagination {
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.pagination-wrap .pagination .page-link {
    margin-left: 0;
    padding: 0 0;
    color: var(--tg-gray-four);
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    width: 52px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    background: transparent;
    font-family: var(--tg-heading-font-family);
}

.pagination-wrap .pagination .page-item.active .page-link,
.pagination-wrap .pagination .page-link:hover {
    background: var(--tg-primary-color);
    border-color: var(--tg-primary-color);
    color: var(--tg-white);
}

.pagination-wrap .pagination .page-link:focus {
    color: var(--tg-white);
    background-color: var(--tg-primary-color);
    border-color: var(--tg-primary-color);
    outline: 0;
    box-shadow: none;
}

.blog-sidebar {
    margin-left: 15px;
}

.sidebar-search {
    margin-bottom: 30px;
}

.sidebar-search form {
    position: relative;
}

.sidebar-search form input {
    width: 100%;
    background: transparent;
    border: 1px solid #E0E6F2;
    border-radius: 10px;
    padding: 20px 55px 20px 28px;
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-paragraph-color);
    height: 65px;
}

.sidebar-search form input::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-gray-four);
}

.sidebar-search form button {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    padding: 0;
    font-size: 24px;
    color: var(--tg-primary-color);
    line-height: 0;
}

.blog-widget {
    border: 1px solid #E0E6F2;
    border-radius: 10px;
    padding: 25px 30px 30px;
    margin-bottom: 30px;
}

.blog-widget:last-child {
    margin-bottom: 0;
}

.blog-widget .bw-title {
    margin-bottom: 25px;
    font-size: 22px;
    position: relative;
    padding-bottom: 12px;
}

.blog-widget .bw-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.bs-cat-list .list-wrap li {
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 15px;
    padding-bottom: 12px;
}

.bs-cat-list .list-wrap li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.bs-cat-list .list-wrap li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    color: var(--tg-paragraph-color);
}

.bs-cat-list .list-wrap li a span {
    font-size: 14px;
    color: var(--tg-gray-four);
    transition: all 0.3s ease-out 0s;
}

.bs-cat-list .list-wrap li a:hover span,
.bs-cat-list .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.rc-post-item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.rc-post-item:last-child {
    margin-bottom: 0;
}

.rc-post-item .thumb {
    width: 75px;
    flex: 0 0 auto;
    margin-right: 15px;
}

.rc-post-item .thumb img {
    border-radius: 8px;
}

.rc-post-item .content .date {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    color: var(--tg-gray-four);
    margin-bottom: 8px;
}

.rc-post-item .content .date i {
    margin-right: 8px;
    color: var(--tg-primary-color);
    font-size: 20px;
}

.rc-post-item .content .title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--tg-paragraph-color);
}

.bs-tag-list .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.bs-tag-list .list-wrap li a {
    font-weight: 500;
    font-size: 16px;
    background: var(--tg-lavender);
    display: block;
    border-radius: 4px;
    padding: 5px 15px;
}

.bs-tag-list .list-wrap li a:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}

/* blog-details */
.blog-details-wrap .row .col-71 {
    width: 71%;
    flex: 0 0 auto;
}

.blog-details-wrap .row .col-29 {
    width: 29%;
    flex: 0 0 auto;
}

.blog-details-thumb {
    margin-bottom: 30px;
}

.blog-details-thumb img {
    border-radius: 10px;
}

.blog-details-content>.title {
    font-size: 36px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.blog-meta-three .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 25px;
}

.blog-meta-three .list-wrap li {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: var(--tg-paragraph-color);
    gap: 5px;
}

.blog-meta-three .list-wrap li i {
    line-height: 0;
    color: var(--tg-primary-color);
    font-size: 20px;
    margin-right: 5px;
}

.blog-meta-three .list-wrap li img {
    border-radius: 50%;
    margin-right: 5px;
}

.blog-meta-three .list-wrap li a {
    color: var(--tg-paragraph-color);
}

.blog-meta-three .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.blog-details-content>p {
    margin-bottom: 15px;
}

.blog-details-content blockquote {
    margin: 50px 50px 60px;
    background: var(--tg-section-background);
    padding: 50px 120px 50px 50px;
    position: relative;
    z-index: 1;
}

.blog-details-content blockquote::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
    background: var(--tg-primary-color);
}

.blog-details-content blockquote::after {
    content: "\f10e";
    font-weight: 700;
    font-size: 52px;
    color: #E2E5F7;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 33px;
    top: 27px;
    z-index: -1;
}

.blog-details-content blockquote p {
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    font-family: var(--tg-heading-font-family);
}

.blog-details-content .title-two {
    font-size: 30px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.bd-inner-wrap {
    margin: 35px 0;
}

.bd-inner-wrap .row .col-46 {
    width: 46%;
    flex: 0 0 auto;
}

.bd-inner-wrap .row .col-54 {
    width: 54%;
    flex: 0 0 auto;
}

.bd-inner-wrap .thumb {
    position: relative;
}

.bd-inner-wrap .thumb img {
    width: 100%;
    border-radius: 10px;
}

.bd-inner-wrap .thumb .play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
}

.bd-inner-wrap .thumb .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    animation: pulse-border 1500ms ease-out infinite;
}

.bd-inner-wrap .content p {
    margin-bottom: 15px;
}

.bd-inner-wrap .content .list-wrap li {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-secondary-color);
    line-height: 1.3;
    margin-bottom: 15px;
}

.bd-inner-wrap .content .list-wrap li:last-child {
    margin-bottom: 0;
}

.bd-inner-wrap .content .list-wrap li img {
    margin-right: 10px;
}

.bd-content-bottom {
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    padding: 15px 0;
    margin-top: 40px;
    margin-bottom: 70px;
}

.bd-content-bottom .post-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.bd-content-bottom .post-tags .title {
    margin-bottom: 0;
    font-size: 18px;
    color: var(--tg-paragraph-color);
}

.bd-content-bottom .post-tags .list-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.bd-content-bottom .post-tags .list-wrap li a {
    background: var(--tg-lavender);
    padding: 9px 22px;
    display: block;
    border-radius: 4px;
}

.bd-content-bottom .post-tags .list-wrap li a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.bd-content-bottom .blog-post-share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.bd-content-bottom .blog-post-share .title {
    margin-bottom: 0;
    font-size: 18px;
    color: var(--tg-paragraph-color);
}

.bd-content-bottom .blog-post-share .list-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.bd-content-bottom .blog-post-share .list-wrap li a {
    color: var(--tg-gray-four);
}

.bd-content-bottom .blog-post-share .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.blog-avatar-wrap {
    border: 1px solid #E6EEFF;
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    padding: 42px 42px;
    background: var(--tg-section-background);
}

.blog-avatar-img {
    width: 115px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.blog-avatar-img img {
    border-radius: 50%;
}

.blog-avatar-info .designation {
    display: block;
    font-weight: 500;
    font-size: 15px;
    color: var(--tg-primary-color);
    line-height: 1;
    margin-bottom: 10px;
}

.blog-avatar-info .name {
    margin-bottom: 10px;
    font-size: 20px;
}

.blog-avatar-info p {
    margin-bottom: 0;
}

.latest-comments {
    margin-bottom: 80px;
}

.comments-wrap-title {
    font-size: 24px;
    margin-bottom: 25px;
}

.comments-box {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #D6DCE9;
    padding-bottom: 30px;
    margin-bottom: 35px;
}

.latest-comments .children {
    margin: 0 0 0 45px;
    padding: 0;
}

.comments-avatar {
    width: 115px;
    flex: 0 0 auto;
    margin-right: 25px;
}

.comments-avatar img {
    border-radius: 50%;
}

.comments-text .avatar-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.comments-text .avatar-name .name {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
}

.comments-text .avatar-name span {
    font-weight: 500;
    font-size: 15px;
    display: block;
    color: var(--tg-primary-color);
}

.comments-text p {
    margin-bottom: 15px;
}

.comments-text .reply-btn {
    background: var(--tg-lavender);
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding: 6px 17px;
    text-transform: uppercase;
    border-radius: 5px;
}

.comments-text .reply-btn:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.comment-respond {
    border: 1px solid #B3CCFF;
    border-radius: 8px;
    padding: 35px 40px 50px;
    background: var(--tg-section-background);
}

.comment-reply-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.comment-form .comment-notes {
    margin-bottom: 15px;
}

.comment-form .form-grp {
    margin-bottom: 15px;
}

.comment-form .form-grp textarea,
.comment-form .form-grp input {
    width: 100%;
    border: 1px solid var(--tg-lavender);
    background: var(--tg-white);
    font-weight: 400;
    font-size: 15px;
    color: var(--tg-paragraph-color);
    padding: 12px 15px;
    height: 50px;
    display: block;
    border-radius: 5px;
}

.comment-form .form-grp textarea::placeholder,
.comment-form .form-grp input::placeholder {
    font-weight: 400;
    font-size: 15px;
    color: var(--tg-gray-four);
}

.comment-form .form-grp textarea {
    min-height: 130px;
    max-height: 130px;
}

.comment-form .checkbox-grp {
    display: flex;
    align-items: center;
    margin: 25px 0;
}

.comment-form .checkbox-grp input {
    width: 22px;
    margin-right: 9px;
    height: 22px;
    cursor: pointer;
}

.comment-form .checkbox-grp label {
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-paragraph-color);
    user-select: none;
}

.comment-form .submit-btn {
    width: auto;
    text-transform: uppercase;
    border-radius: 30px;
    letter-spacing: 0.02em;
    padding: 13px 25px;
}

.comment-form .row {
    margin: 0 -10px;
}

.comment-form .row [class*="col-"] {
    padding: 0 10px;
}


/*=============================
	25. Brand
===============================*/
.brand-active [class*="col-"] {
    padding: 0 15px;
}

.brand-item {
    min-height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand-item img {
    cursor: pointer;
    filter: grayscale(100%);
    opacity: .5;
}

.brand-item:hover img {
    filter: grayscale(0);
    opacity: 1;
}

/* brand-two */
.brand-item-wrap {
    border-bottom: 1px solid #DEE4F0;
    padding-bottom: 40px;
}

.brand-item-wrap .title {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 25px;
    color: var(--tg-paragraph-color);
    font-family: var(--tg-body-font-family);
}

.brand-item-wrap-two {
    border-top: 1px solid #DEE4F0;
    padding: 70px 0;
}

.brand-area-seven {
    border-top: 1px solid #DEE4F0;
    padding: 70px 0;
}

/*=============================
	26. Error
===============================*/
.error-area {
    padding: 210px 0;
}

.error-content {
    text-align: center;
}

.error-content .error-404 {
    font-size: 400px;
    margin-bottom: 50px;
    line-height: .74;
    font-weight: 800;
}

.error-content .error-404 span {
    color: var(--tg-primary-color);
}

.error-content .title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 36px;
    text-transform: capitalize;
}

.error-content p {
    margin-bottom: 35px;
    color: var(--tg-gray-four);
    font-weight: 400;
}


/*=============================
	27. Footer
===============================*/
.footer-bg {
    background-position: center;
    background-size: cover;
}

.footer-top {
    padding: 100px 0 40px;
}

.footer-widget {
    margin-bottom: 40px;
}

.footer-widget .fw-title {
    font-size: 24px;
    margin-bottom: 0;
    color: var(--tg-white);
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 16px;
}

.footer-widget .fw-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.footer-info .list-wrap li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}

.footer-info .list-wrap li:last-child {
    margin-bottom: 0;
}

.footer-info .list-wrap li .icon {
    font-size: 22px;
    line-height: 0;
    color: var(--tg-lavender);
    margin-top: 3px;
    margin-right: 12px;
}

.footer-info .list-wrap li .content a,
.footer-info .list-wrap li .content p {
    color: var(--tg-gray-three);
    margin-bottom: 0;
}

.footer-info .list-wrap li .content p span {
    font-weight: 700;
    color: var(--tg-white);
}

.footer-info .list-wrap li .content a:hover {
    color: var(--tg-white);
}

.footer-link .list-wrap li {
    margin-bottom: 10px;
}

.footer-link .list-wrap li:last-child {
    margin-bottom: 0;
}

.footer-link .list-wrap li a {
    color: var(--tg-gray-three);
    position: relative;
}

.footer-link .list-wrap li a:hover {
    color: var(--tg-white);
}

.footer-link .list-wrap li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background: var(--tg-white);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: transform 0.4s cubic-bezier(.74, .72, .27, .24);
}

.footer-link .list-wrap li a:hover::before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.footer-top .row [class*="col-"]:nth-child(3) .footer-widget {
    margin-left: 40px;
}

.footer-newsletter p {
    margin-bottom: 20px;
    color: var(--tg-gray-three);
    width: 90%;
}

.footer-newsletter form {
    position: relative;
    margin-bottom: 15px;
}

.footer-newsletter form input {
    width: 100%;
    border: none;
    background: #1E2E4E;
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-white);
    border-radius: 30px;
    padding: 12px 125px 12px 22px;
    height: 46px;
}

.footer-newsletter form input::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: var(--tg-white);
    text-transform: capitalize;
}

.footer-newsletter form button {
    border: none;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    font-weight: 700;
    font-size: 14px;
    border-radius: 30px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 23px;
    height: 46px;
}

.footer-newsletter form button:hover {
    background: var(--tg-secondary-color);
}

.footer-newsletter span {
    display: block;
    color: var(--tg-gray-three);
    font-size: 15px;
}

.footer-bottom {
    border-top: 1px solid #253352;
    padding: 48px 0;
}

.footer-bottom .f-logo {
    margin-bottom: 10px;
}

.copyright-text p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #7c8db1;
}

.footer-social .list-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.footer-social .list-wrap li a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A2948;
    border-radius: 50%;
    color: var(--tg-white);
    font-size: 20px;
}

.footer-social .list-wrap li a:hover {
    background: var(--tg-primary-color);
}

/* footer-two */
.footer-bg-two {
    background-size: cover;
    background-position: center;
}

.footer-top-two {
    padding: 100px 0 40px;
}

.footer-top-two .fw-logo {
    margin-bottom: 30px;
}

.footer-content>p {
    margin-bottom: 20px;
    width: 90%;
}

.footer-top-two .footer-info .list-wrap li .icon {
    color: var(--tg-primary-color);
}

.footer-top-two .footer-info .list-wrap li .content a,
.footer-top-two .footer-info .list-wrap li .content p {
    color: var(--tg-paragraph-color);
}

.footer-top-two .footer-info .list-wrap li .content a:hover {
    color: var(--tg-primary-color);
}

.footer-top-two .footer-info .list-wrap li .content p span {
    color: var(--tg-primary-color);
}

.footer-top-two .footer-widget .fw-title {
    color: var(--tg-secondary-color);
}

.footer-top-two .footer-link .list-wrap li a {
    color: var(--tg-paragraph-color);
}

.footer-top-two .footer-link .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.footer-top-two .footer-link .list-wrap li a::before {
    background: var(--tg-primary-color);
}

.footer-top-two .footer-newsletter p {
    color: var(--tg-paragraph-color);
}

.footer-top-two .footer-newsletter form input {
    background: #EEF1F5;
    color: var(--tg-paragraph-color);
}

.footer-top-two .footer-newsletter form input::placeholder {
    color: var(--tg-paragraph-color);
}

.footer-top-two .footer-newsletter form {
    margin-bottom: 20px;
}

.footer-social.footer-social-two .list-wrap {
    justify-content: flex-start;
}

.footer-social.footer-social-two .list-wrap li a {
    background: #F1F1F1;
    color: var(--tg-paragraph-color);
}

.footer-social.footer-social-two .list-wrap li a:hover {
    background: var(--tg-paragraph-color);
    color: var(--tg-white);
}

.footer-bottom-two {
    border-top: 1px solid #EAEAEA;
    padding: 27px 0;
}

.copyright-text-two p {
    color: #96A1B8;
    margin-bottom: 0;
    font-size: 14px;
}

/* footer-three */
.footer-area-three {
    background: #051433;
}

.footer-top-three {
    padding: 100px 0 55px;
}

.footer-top-three .fw-logo {
    margin-bottom: 10px;
}

.footer-top-three .footer-content>p {
    color: #96A1B8;
}

.footer-social.footer-social-three .list-wrap {
    justify-content: flex-start;
}

.footer-bottom-three {
    border-top: 1px solid #1A264F;
    padding: 27px 0;
}

/*=============================
	28. Preloader
===============================*/
#preloader {
    background-color: var(--tg-white);
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0px;
    top: 0px;
    z-index: 999;
}

#loading-center {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader .loader-outter {
    position: absolute;
    border: 4px solid var(--tg-primary-color);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader .loader-inner {
    position: absolute;
    border: 4px solid var(--tg-primary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

@-webkit-keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes loader-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.background-right {
    background-position: right;
}

.slider-1 {
    background-image: url('/assets/img/banner/slider_3.webp') !important;
}

.slider-2 {
    background-image: url('/assets/img/banner/slider_2.webp') !important;
}

.slider-3 {
    background-image: url('/assets/img/banner/slider_1.webp') !important;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}